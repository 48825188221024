// export const Sound = (props: any) => (
//   <svg
//     width="24"
//     height="24"
//     viewBox="0 0 24 24"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//     {...props}
//   >
//     <g id="sound">
//       <rect id="line5" x="11" y="4" width="1" height="16" fill="currentColor" />
//       <rect id="line4" x="15" y="7" width="1" height="10" fill="currentColor" />
//       <rect id="line3" x="7" y="7" width="1" height="10" fill="currentColor" />
//       <rect id="line2" x="19" y="10" width="1" height="4" fill="currentColor" />
//       <rect id="line1" x="3" y="10" width="1" height="4" fill="currentColor" />
//     </g>
//   </svg>
// );

import React, { useEffect } from 'react';

export const Sound = (props: any) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const handleClick = () => {
    const status = !isOpen
    if (status) {
      window?.document.getElementById("sound").play();
    } else {
      window?.document.getElementById("sound").pause();
    }
    setIsOpen(status);
  }

  const { musicSwitch = isOpen, handleMusicSwitch = handleClick } = props

  return (
    <div onClick={handleMusicSwitch}>
      {!musicSwitch ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" >
          <rect x="11" y="4" width="1" height="16" fill="white" >
            <animate
              attributeName="y" from="4" to="11"
              dur="0.3s" repeatCount="1" fill="freeze" />
            <animate
              attributeName="height" from="16" to="2"
              dur="0.3s" repeatCount="1" fill="freeze" />
          </rect>
          <rect x="15" y="7" width="1" height="10" fill="white" >
            <animate
              attributeName="y" from="7" to="11"
              dur="0.3s" repeatCount="1" fill="freeze" />
            <animate
              attributeName="height" from="10" to="2"
              dur="0.3s" repeatCount="1" fill="freeze" />
          </rect>
          <rect x="7" y="7" width="1" height="10" fill="white" >
            <animate
              attributeName="y" from="7" to="11"
              dur="0.3s" repeatCount="1" fill="freeze" />
            <animate
              attributeName="height" from="10" to="2"
              dur="0.3s" repeatCount="1" fill="freeze" />
          </rect>
          <rect x="19" y="10" width="1" height="4" fill="white">
            <animate
              attributeName="y" from="10" to="11"
              dur="0.3s" repeatCount="1" fill="freeze" />
            <animate
              attributeName="height" from="4" to="2"
              dur="0.3s" repeatCount="1" fill="freeze" />
          </rect>
          <rect x="3" y="10" width="1" height="4" fill="white" >
            <animate
              attributeName="y" from="10" to="11"
              dur="0.3s" repeatCount="1" fill="freeze" />
            <animate
              attributeName="height" from="4" to="2"
              dur="0.3s" repeatCount="1" fill="freeze" />
          </rect>
        </svg>) : null}

      {musicSwitch ?
        (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" >
            <rect x="11" y="11" width="1" height="2" fill="white" >
              <animate
                attributeName="y" from="11" to="4"
                dur="0.3s" repeatCount="1" fill="freeze" />
              <animate
                attributeName="height" from="2" to="16"
                dur="0.3s" repeatCount="1" fill="freeze" />
            </rect>
            <rect x="15" y="11" width="1" height="2" fill="white"  >
              <animate
                attributeName="y" from="11" to="7"
                dur="0.3s" repeatCount="1" fill="freeze" />
              <animate
                attributeName="height" from="2" to="10"
                dur="0.3s" repeatCount="1" fill="freeze" />
            </rect>
            <rect x="7" y="11" width="1" height="2" fill="white" >
              <animate
                attributeName="y" from="11" to="7"
                dur="0.3s" repeatCount="1" fill="freeze" />
              <animate
                attributeName="height" from="2" to="10"
                dur="0.3s" repeatCount="1" fill="freeze" />
            </rect>
            <rect x="19" y="11" width="1" height="2" fill="white">
              <animate
                attributeName="y" from="11" to="10"
                dur="0.3s" repeatCount="1" fill="freeze" />
              <animate
                attributeName="height" from="2" to="4"
                dur="0.3s" repeatCount="1" fill="freeze" />
            </rect>
            <rect x="3" y="11" width="1" height="2" fill="white" >
              <animate
                attributeName="y" from="11" to="10"
                dur="0.3s" repeatCount="1" fill="freeze" />
              <animate
                attributeName="height" from="2" to="4"
                dur="0.3s" repeatCount="1" fill="freeze" />
            </rect>
          </svg>) : null}
    </div>
  );
};
import { type MotionValue, useTransform } from 'framer-motion';

import logo from '../Frame/images/logo.png';
import * as SharedS from '../shared/styles';
import * as S from './styles';

function useParallax(value: MotionValue<number>, begin: number, count: number) {
  const input = Array.from({ length: count })
    .map((_, i) => i + begin)
    .map((i) => i * document.documentElement.clientHeight);
  const clipPath = useTransform(value, input, [
    'polygon(0% 0%, 0% 95%, 100% 100%, 100% 5%)',
    'polygon(0% 0%, 0% 95%, 100% 100%, 100% 8.3%)',
    'polygon(0% 0%, 0% 95%, 100% 100%, 100% 8.3%)',
    'polygon(0% 0%, 0% 91.7%, 100% 100%, 100% 8.3%)',
  ]);
  const y = useTransform(value, input, ['100vh', '-10vh', '-10vh', '-120vh']);
  const bgY = useTransform(value, input, ['-16vh', '-16vh', '-10vh', '-10vh']);
  const doorY = useTransform(value, input, [
    '38.125vh',
    '38.125vh',
    '22.625vh',
    '22.625vh',
  ]);
  const cloudScale = useTransform(value, input, ['1', '1', '1.412', '1.412']);
  // cloud unmoved
  const cloudUnmoved = {
    scale: cloudScale,
  };
  // cloud left
  const cloudLeft = {
    x: useTransform(value, input, ['-2vw', '-2vw', '-5.65vw', '-5.65vw']),
    scale: cloudScale,
  };
  // cloud right
  const cloudRight = {
    x: useTransform(value, input, ['0', '0', '4.32vw', '4.32vw']),
    scale: cloudScale,
  };
  const contentWrapper = {
    opacity: useTransform(value, input, ['0', '0', '1', '1']),
    y: useTransform(value, input, ['0px', '0px', '-10px', '-10px']),
  };
  return {
    clipPath,
    y,
    bgY,
    doorY,
    // photonY,
    cloudUnmoved,
    cloudLeft,
    cloudRight,
    contentWrapper,
  };
}

export const stepCount = 3;

export const Viewblock = () => {
  return (
    <SharedS.Container>
      <SharedS.BgWrapper>
        <S.Logo alt="Logo" imageSrc={logo} />
      </SharedS.BgWrapper>
      <S.ContentWrapper style={{
        x: '-50%',
        y: '0'
      }}>
        <SharedS.Content style={{ fontWeight: 'bold', padding: '0 20px' }}>
          Change a device or the ratio of your screen
        </SharedS.Content>
        <SharedS.Content style={{ opacity: .5, marginTop: '1em', lineHeight: '1.5em', padding: '0 20px', fontSize: '1.2em' }}>
          To ensure optimum performance, please switch to PC/ Desktop device.
        </SharedS.Content>
      </S.ContentWrapper>
    </SharedS.Container >
  );
};

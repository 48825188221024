import { motion } from 'framer-motion';
import { media } from 'styled-bootstrap-grid';
import styled, { keyframes } from 'styled-components';

import { PreloadImage } from '../PreloadImage/PreloadImage';
import { twinkling } from '../shared/animations';
import * as SharedS from '../shared/styles';
import angel from './images/angel@0.5x.png';

export const falling = keyframes`
  0% {
    opacity: 1;
    transform: translateX(49.3vw) translateY(61.875vh) scale(0.75);
  }

  40% {
    opacity: 1;
    transform: translateX(54vw) translateY(77.875vh) scale(1.27);
  }

  70% {
    opacity: 0.2;
    transform: translateX(55.5vw) translateY(81.875vh) scale(1.33);
  }

  100% {
    opacity: 0;
    transform: translateX(57vw) translateY(85.875vh) scale(1.4);
  }
`;

const fallingDuration = 22;
export const Fall = styled(PreloadImage)`
  top: 13vh;
  left: 10vw;
  width: 21.11vw;
  height: auto;
  transform-origin: left top;
  animation: ${falling} ${fallingDuration}s ease-out infinite;
  opacity: 0;

  & + & {
    animation-delay: ${fallingDuration / 2}s;
  }
`;

export const float = keyframes`
  0% {
    transform: translateX(0) translateY(0);
  }

  10% {
    transform: translateX(0) translateY(0);
  }

  50% {
    transform: translateX(0) translateY(-3vh);
  }

  100% {
    transform: translateX(0) translateY(0);
  }
`;

const fallingDuration2 = 15;
export const Fall2 = styled(PreloadImage)`
  top: 10vh;
  width: 11.11vw;
  height: auto;
  transform-origin: left top;
  animation: ${falling} ${fallingDuration2}s ease-out infinite;
  opacity: 0;

  & + & {
    animation-delay:3s;
  }
`;


const fallingDuration3 = 12;
export const Fall3 = styled(PreloadImage)`
  top: 10vh;
  left: 20vw;
  width: 11.11vw;
  height: auto;
  transform-origin: left top;
  animation: ${falling} ${fallingDuration3}s ease-out infinite;
  opacity: 0;

  & + & {
    animation-delay: 2s;
  }
`;


export const Angel = styled(motion.div)`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 30vh;
  right: -20vw;
  transform-origin: right bottom;
  background-image: url(${angel});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  animation: ${float} 3s ease-in-out infinite;
  ${media.max.lg`
    width: 130vw;
    height: 130vh;
    top: 20vh;
    right: -30vw;
  `}
`;

export const Light = styled.div`
  position: absolute;
  top: -16vh;
  left: 8vw;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-image: radial-gradient(
    80% 80% at 50% 50%,
    rgba(192, 183, 102, 0.8) 0%,
    rgba(192, 183, 102, 0.3) 18.75%,
    rgba(192, 183, 102, 0) 60%
  );
  // background-color: rgba(192, 183, 102, 0.08);
  animation: ${twinkling} 4s ease-in-out infinite;
`;

export const ContentWrapper = styled(SharedS.ContentWrapper)`
  ${media.max.lg`
    top: 30vh;
  `}
`;
import ProgressBar from "@ramonak/react-progress-bar";
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { whitelist } from '../../whitelist';
import { PreloadImage } from '../PreloadImage/PreloadImage';
import * as SharedS from '../shared/styles';
// import useCandyMachineV3 from "../Wallet/hooks/useCandyMachineV3";
import attack from './images/attack-keep-png.png';
import bg from './images/bg.png';
import completed from './images/completed.png';
import hit from './images/hit-keep-png.png';
import idle from './images/idle-keep-png.png';
import * as S from './styles';
export const stepCount = 3;

export const Mint = () => {
  const [balance, setBalance] = useState({
    available: 555,
    items: 555,
    remaining: 555,
  });
  const [attackLock, setAttackLock] = useState(false);
  const [attackStatus, setAttackStatus] = useState(false);
  const [attackEffect, setAttackEffect] = useState(false);
  const [completedSeal, setCompletedSeal] = useState(false)
  const [statusMsg, setStatusMsg] = useState(false/*'idle'*/);
  const [address, setAddress] = useState('');

  const handleAttack = async () => {
    if (attackLock) return
    try {
      // await candyMachineV3?.mint()
      if (!address) {
        setBalance({
          available: 555,
          items: 555,
          remaining: 0,
        })
        throw new Error('Please enter your solona wallet address');
      }
      if (!checkWhitelist(address)) {
        setBalance({
          available: 555,
          items: 555,
          remaining: 0,
        })
        throw new Error('You are not in the whitelist');
      }
      setAttackLock(true)
      console.log('handleAttack');
      // 你有資格
      toast.success('You are eligible!');
      setBalance({
        available: 555,
        items: 555,
        remaining: 555,
      })
      setAttackStatus(true)
      setTimeout(() => {
        setAttackEffect(true)
      }, 100)
      setTimeout(() => {
        setAttackEffect(false)
        setAttackStatus(false)
        setAttackLock(false)
      }, 600)
    } catch (e) {
      toast.error(e?.message);
      console.log('error', `Mint failed! ${e?.message}`);
    }
  }

  const checkWhitelist = (address: string) => {
    return whitelist.map(item => item.toLowerCase()).includes(address?.toLowerCase())
  }

  const handleInputAddress = (e: any) => {
    setAddress(e.target.value)
  }

  // const candyMachinOps = {
  // };
  // const candyMachineV3 = useCandyMachineV3(
  //   candyMachinOps
  // );

  // useEffect(() => {
  //   if (!candyMachineV3.candyMachine) return;
  //   const { items } = candyMachineV3;
  //   setBalance(items);

  // }, [candyMachineV3.items]);

  // useEffect(() => {
  //   if (!candyMachineV3.candyMachine) return;
  //   console.log('candyMachineV3.guardStates', candyMachineV3.guardStates)
  //   const msg = candyMachineV3.guardStates.default
  //     ?.messages?.[0] || null;
  //   setStatusMsg(msg)
  //   if (msg === 'Mint limit for each user has been reached.') {
  //     setCompletedSeal(true)
  //   }
  // }, [candyMachineV3.guardStates]);

  // console.log('candyMachineV3', candyMachineV3)

  return (<>
    <SharedS.Container style={{ position: 'initial', height: '100%' }} className="aaaa">
      <SharedS.BgWrapper>
        <PreloadImage
          imageSrc={bg}
          alt=""
          style={{
            width: '100%',
            height: '100vh',
            // y: bgY,
            // x: '-0vh',
          }}
        />
      </SharedS.BgWrapper>
      <S.ContentWrapper style={{
        x: '-50%',
        y: '0'
      }}>
        <S.Status>
          {/* <ProgressBar className="wrapper" completed={((balance.remaining / balance.available * 100) > 90 ? (balance.remaining / balance.available * 100) - 4.5 : (balance.remaining / balance.available * 100))} />
          <S.StatusText>{balance.remaining}<span>/{balance.available}</span></S.StatusText> */}
        </S.Status>
        <S.Frog className={balance.remaining === 0 || completedSeal ? 'SOLDOUT' : ''} imageSrc={attackStatus ? hit : idle} alt="Frog Image" />
        <SharedS.BgDownloadImage src={attack} alt='downloading' />
        <SharedS.BgDownloadImage src={hit} alt='downloading' />
        <SharedS.BgDownloadImage src={completed} alt='downloading' />
        {completedSeal && <S.CompletedSeal className="completedSeal" imageSrc={completed} alt="completed Image" />}
        {(1 || balance.remaining && !statusMsg) ? <S.InputBox>
          <S.Input placeholder="Please enter your solona wallet address" onChange={handleInputAddress} />
          <S.Button onClick={handleAttack}>Check</S.Button>
        </S.InputBox> : <S.SoldOut>{balance.remaining ? statusMsg : 'SOLD OUT'}</S.SoldOut>}
      </S.ContentWrapper>
      {attackEffect && <S.Attack imageSrc={attack} alt="Attack Image" />}
      <style>
        {"@media screen and (max-width: 990px) {#body { overflow: initial;}#root{ height: initial;}}"}
      </style>
    </SharedS.Container >

    <S.Rules>
      <S.Rule />
      <S.Hr />
      <S.RulesTitle>What is Genesis Frog NFT?</S.RulesTitle>
      <S.RulesContent>
        Genesis Frog NFT is the first NFT of Destiny of Gods, with total supply 1111, mint price will be free.<br />
        <br />
        The utility of NFT including:<br />
        <br />
        <ul>
          <li>1. Priority Internal beta rights + 3 testing invitation codes</li>
          <li>2. Increased Action point recovery in the game</li>
          <li>3. Free one-time card-drawing every day</li>
          <li>4. Other series of NFT airdrops</li>
        </ul>
      </S.RulesContent>

      <S.RulesTitle>How can I own Genesis Frog NFT?</S.RulesTitle>
      <S.RulesContent>Follow our Twitter and Discord for the whitelist information</S.RulesContent>

      <S.RulesTitle>Which blockchain will Genesis Frog NFT be deployed on?</S.RulesTitle>
      <S.RulesContent>Solana</S.RulesContent>

      <S.RulesTitle>What's the maximum number of NFTs allowed per wallet for minting?</S.RulesTitle>
      <S.RulesContent>The maximum minting limit per wallet is one<br />
        <br />
        The whitelist guarantees that there will be no over-issuance</S.RulesContent>
    </S.Rules>
  </>
  );
};

import { type MotionValue, useTransform } from 'framer-motion';
import { useEffect, useState } from 'react';

import { PreloadImage } from '../PreloadImage/PreloadImage';
import * as SharedS from '../shared/styles';
import { SceneProps } from '../shared/types';
import cabinetLeft from './images/cabinet-left@0.5x.png';
import cabinetRight from './images/cabinet-right@0.5x.png';
import destinyOfGodsDestiny from './images/destiny-of-gods-destiny.svg';
import destinyOfGodsGods from './images/destiny-of-gods-gods.svg';
import destinyOfGodsOf from './images/destiny-of-gods-of.svg';
import frontBook from './images/front-book@0.5x.png';
import title from './images/title.svg';
import titleMobile from './images/title-mobile.svg';
import * as S from './styles';

function useParallax(value: MotionValue<number>, begin: number, count: number, isMobile: boolean) {
  const input = Array.from({ length: count })
    .map((_, i) => i + begin)
    .map((i) => i * document.documentElement.clientHeight);
  const clipPath = useTransform(value, input, [
    'polygon(0% 0%, 0% 95%, 100% 100%, 100% 0%)',
    'polygon(0% 0%, 0% 95%, 100% 100%, 100% 0%)',
    'polygon(0% 0%, 0% 91.7%, 100% 100%, 100% 0%)',
  ]);
  const y = useTransform(value, input, ['0vh', '0vh', '-100vh']);
  const cabinetWrapper = {
    y: useTransform(value, input, isMobile ? ['5vh', '5vh', '5vh'] : ['10vh', '5vh', '5vh']),
    scaleY: useTransform(value, input, isMobile ? ['1.1', '1.1', '1.1'] : ['1', '1.2', '1.2']),
    scaleX: useTransform(value, input, isMobile ? ['1.1', '1.1', '1.1'] : ['1', '1.4', '1.4']),
    width: useTransform(value, input, isMobile ? ['100%', '160%', '300%'] : ['100%', '100%', '100%']),
    x: useTransform(value, input, isMobile ? ['-3%', '-22%', '-100%'] : ['0', '0', '0']),
  };
  const destinyOfGods = {
    scale: useTransform(value, input, isMobile ? ['0.4', '0.48', '0.55'] : ['0.75', '1', '1']),
    opacity: useTransform(value, input, isMobile ? ['0.4', '0.8', '1'] : ['1', '1', '1']),
  };
  const destiny = {
    scale: destinyOfGods.scale,
    opacity: destinyOfGods.opacity,
    x: useTransform(value, input, isMobile ? ['calc(0px - 50%)', 'calc(0px - 50%)', 'calc(0px - 50%)',] : [
      'calc(0px - 50%)',
      'calc(-305px - 50%)',
      'calc(-305px - 50%)',
    ]),
    y: useTransform(value, input, isMobile ? ['-110%', '-110%', '-110%',] : ['-150%', '-50%', '-50%']),
  };
  const of = {
    scale: destinyOfGods.scale,
    opacity: destinyOfGods.opacity,
    x: useTransform(value, input, isMobile ? ['calc(0px - 50%)', 'calc(0px - 50%)', 'calc(0px - 50%)',] : [
      'calc(0px - 50%)',
      'calc(107px - 50%)',
      'calc(107px - 50%)',
    ]),
    y: useTransform(value, input, ['-50%', '-50%', '-50%']),
  };
  const gods = {
    scale: destinyOfGods.scale,
    opacity: destinyOfGods.opacity,
    x: useTransform(value, input, isMobile ? ['calc(-7px - 50%)', 'calc(-7px - 50%)', 'calc(-7px - 50%)',] : [
      'calc(-7px - 50%)',
      'calc(412px - 50%)',
      'calc(412px - 50%)',
    ]),
    y: useTransform(value, input, isMobile ? ['10%', '10%', '10%'] : ['50%', '-50%', '-50%']),
  };
  const start = {
    opacity: useTransform(value, input, ['0', '1', '1']),
    y: useTransform(value, input, ['0px', '-10px', '-10px']),
  };
  return {
    clipPath,
    y,
    cabinetWrapper,
    destiny,
    of,
    gods,
    start,
  };
}

export const stepCount = 2;

export const Hero = ({
  scrollY,
  beginStep,
  isReadyToStart,
  isStarted,
  onClickStart,
  step,
  isMobile
}: SceneProps & {
  isReadyToStart: boolean;
  isStarted: boolean;
  onClickStart: () => void;
    step: number;
}) => {
  const {
    clipPath,
    y,
    cabinetWrapper: cabinetWrapperStyle,
    destiny: destinyStyle,
    of: ofStyle,
    gods: godsStyle,
    start: startStyle,
  } = useParallax(scrollY, beginStep, stepCount + 1, isMobile);

  return (
    <SharedS.Container style={{ y, clipPath }}>
      <S.BgWrapper>
        <S.BgBooks style={{ x: '-50%' }} />
        <S.BgBook2 style={{ x: '-50%' }} />
        <S.TreeContainer className={step >= 1 ? 'CutHeroNextTreePage1AnimationIn' : 'CutHeroNextTreePage1AnimationOut'}>
          <S.Tree style={{ x: '-50%', }} />
        </S.TreeContainer>
        <S.TreeSmoke1
          style={{ y: 'calc(26.875vh - 50%)' }}
        />
        <S.TreeSmoke2
          style={{ y: 'calc(26.875vh - 50%)' }}
        />
        <S.CabinetWrapper style={cabinetWrapperStyle}>
          <PreloadImage
            shouldContain
            imageSrc={cabinetRight}
            alt=""
            justify="right"
            style={{
              width: 'auto',
              height: '120vh',
              right: '-23vh',
              top: '-10vh',
              ...(
                isMobile && {
                  height: '70vh',
                  top: '0vh',
                }
              )
            }}
          />
          <PreloadImage
            shouldContain
            imageSrc={cabinetLeft}
            alt=""
            style={{
              width: 'auto',
              height: '138.5vh',
              left: '-40vh',
              top: '-10vh',
              ...(
                isMobile && {
                  height: '100vh',
                  top: '-10vh',
                }
              )
            }}
          />
        </S.CabinetWrapper>
        <S.Bird />
        <PreloadImage
          shouldContain
          imageSrc={frontBook}
          alt=""
          style={{
            width: 'auto',
            height: '32.5vh',
            left: '50%',
            x: '-50%',
            top: '50%',
            y: 'calc(33.75vh - 50%)',
          }}
        />
        <S.Light />
        {!isMobile && <S.Lights1 />}
        <S.Lights21 />
        <S.Lights22 />
        {!isMobile && <S.Lights2 />}
        <S.Lights3 />
        {!isMobile && <S.Lights4 />}
      </S.BgWrapper>
      <S.LogoContainer hide={isStarted} clickable={isReadyToStart}>
        <PreloadImage
          shouldContain
          imageSrc={destinyOfGodsDestiny}
          alt=""
          style={{
            width: 'auto',
            height: '101px',
            left: '50%',
            top: 'calc(50% - 10vh)',
            transformOrigin: 'center center',
            ...destinyStyle,
          }}
        />
        <PreloadImage
          shouldContain
          imageSrc={destinyOfGodsOf}
          alt=""
          style={{
            width: 'auto',
            height: '101px',
            left: '50%',
            top: 'calc(50% - 10vh)',
            transformOrigin: 'center center',
            ...ofStyle,
          }}
        />
        <PreloadImage
          shouldContain
          imageSrc={destinyOfGodsGods}
          alt=""
          style={{
            width: 'auto',
            height: '101px',
            left: '50%',
            top: 'calc(50% - 10vh)',
            transformOrigin: 'center center',
            ...godsStyle,
          }}
        />
        {!isMobile && <S.Start style={startStyle} onClick={onClickStart} />}
      </S.LogoContainer>
      <S.ContentWrapper show={isStarted}>
        <SharedS.Title src={isMobile ? titleMobile : title} className={step === 0 || step === 1 ? 'contentLoaded' : ''} />
        <SharedS.Content className={step === 0 || step === 1 ? 'contentLoaded' : ''}>
          In a savage era on the Deity Continent, spirits revolted, unleashing
          ferocious beasts. Mortal Parades, channels of gods through unyielding
          faith, founded the Glazed Organization.
          <br />
          Through sacrifice, they subdued most beasts, nurturing the 'Tree of
          Faith' for peace.
        </SharedS.Content>
      </S.ContentWrapper>
    </SharedS.Container>
  );
};

import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';

import { PreloadImage } from '../PreloadImage/PreloadImage';
import * as Shared from '../shared/styles';
import RuleSvg from './images/rule.svg';
import Star from './images/star.png';

export const Logo = styled(PreloadImage)`
  width: 80px;
  height: 80px;
  left: calc(50% - 40px);
  top: 20vh;
`;

export const ContentWrapper = styled(Shared.ContentWrapper)`
  top: 120px;
  height: calc(100% - 140px);
  // background-color: #000;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: initial;
  // overflow: hidden;
`;

export const Status = styled.div`
  width: 90%;
  flex: 1;
  // background-color: #0FF;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  .wrapper{
    width: calc(100% - 7em) !important;
    // flex: 12;
    div{
      // width: initial !important;
      background-color: initial !important;
      border: 1px solid #FFF;
      padding: 5px;
      height: 17px !important;
      overflow: initial !important;
      // padding-right: 17px !important;
      div{
        background-color: #FFF !important;
        height: 5px !important;
        span{
          display: none !important;
        }
      }
    }
  }
`;

export const StatusText = styled.div`
  line-height: 27px;
  // min-width: 7em;
  margin-left: 0.5em;
  flex: 1;
  span{
    color: #FFF;
    opacity: 0.6;
  }
`

export const Frog = styled(PreloadImage)`
  flex: 15;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  height: calc(100% - 15vh);
  margin: 0 auto;
  max-width: 100%;
  // filter: grayscale(40%);
  // ${media.max.sm`
    top: calc(50% - 20vh);
    width: 100vw;
    height: initial;
  // `}
`;

export const Attack = styled(PreloadImage)`
  flex: 8;
  position: absolute;
  left: 50%;
  top: calc(50% - 50vh);
  transform: translateX(-50%);
  height: 100vh;
  width: auto;
  margin: 0 auto;
  opacity: 0.8;
  z-index: 9999;
  // ${media.max.sm`
    top: calc(50% - 15vh);
    width: 100vw;
    height: initial;
  // `}
`;

export const CompletedSeal = styled(PreloadImage)`
  flex: 8;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(50% - 12vh);
  width: auto;
  height: calc(100% - 50vh);
  margin: 0 auto;
  opacity: 0.8;
  z-index: 9999;
`;

export const InputBox = styled.div`
  width: 90%;
  // background-color: #F00;
  flex: 1;
  display: flex;
  height: 100px;
  max-width: 600px;
  margin: 0 auto;
  // padding-top: 20px;
  max-height: 3em;
`;

export const Input = styled.input`
  width: 100%;
  background-color: #FFFFFF99;
  padding: 15px;
  outline: none;
  border: none;
  border-radius: 10px;
  flex: 8;
  font-family: 'Roboto Mono';
`;

export const SoldOut = styled.div`
  width: 100%;
  border-radius: 10px;
  // flex: 1;
  cursor: pointer;
  font-family: 'Roboto Mono';
  opacity: 0.8;
  font-size: 2em;
  font-weight: bold;
  padding-top: 10px;
  margin: 0 auto;
`;

export const Button = styled.button`
  width: 100%;
  background-color: #00F;
  border-radius: 10px;
  flex: 1;
  margin-left: 10px;
  background-color: #FFF;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: 'Roboto Mono';
  opacity: 0.8;
  font-size: 1.5em;
  // font-weight: bold;
  color: #333;
`;


export const Rules = styled.div`
  width: calc(100% - 20vw);
  height: auto;
  position: absolute;
  word-wrap: break-word;
  color: #FFF;
  z-index: 1;
  text-align: left;
  font-family: 'Roboto Mono';
  line-height: 1.5em;
  padding: 50px 10vw 50px 10vw;
`

export const Rule = styled.div`
  width: 8em;
  height: 2em;
  margin-left: 0.5em;
  margin-top: 1em;
  background-image: url(${RuleSvg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`

export const Hr = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  background-color: #FFF;
  height: 1px;
  width: 100%;
  margin: 50px 0 30px 0;
  opacity: 0.5;
`

export const RulesTitle = styled.h1`
  font-size: 1em;
  margin-bottom: 20px;
  padding-left: 2em;
  &:before {
    content: '';
    width: 1em;
    height: 1em;
    position: absolute;
    background-image: url(${Star});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: -1.5em;
    margin-top: 0.25em;
  }
`

export const RulesContent = styled.p`
  font-size: 1em;
  padding-left: 2em;
  opacity: 0.5;
  margin-bottom: 30px;
`
import { motion } from 'framer-motion';
import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';

import * as SharedS from '../shared/styles';

export const Door = styled(motion.div)`
  position: absolute;
  width: 59.51vw;
  height: 108.75vh;
  bottom: 0;
  transform-origin: 10% bottom;
  left: 50%;
`;

export const ContentWrapper = styled(SharedS.ContentWrapper)`
  top: 75vh;
  ${media.max.lg`
    top: 25vh;
  `}
`;

export const Attack = styled(SharedS.Attack).attrs<{ action: string }>({
  action: 'Attack',
})`
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: calc(50%);
  margin-left: -50px;
  ${media.max.lg`
    top: 60%;
  `}
`;

import { motion, type MotionValue, useTransform } from 'framer-motion';

import { PreloadImage } from '../PreloadImage/PreloadImage';
import * as SharedS from '../shared/styles';
import { SceneProps } from '../shared/types';
import bg from './images/bg@0.5x.png';
import bgCloudRight from './images/bg-cloud-right@0.5x.png';
import cloudLeftNew from './images/cloud-left-new@0.5x.png';
import cloudRightNew from './images/cloud-right-new@0.5x.png';
import fall from './images/fall@0.5x.png';
import light from './images/light@0.5x.png';
import middleLeftNew from './images/middle-left-new@0.5x.png';
import title from './images/title.svg';
import * as S from './styles';

function useParallax(value: MotionValue<number>, begin: number, count: number, isMobile: boolean) {
  const input = Array.from({ length: count })
    .map((_, i) => i + begin)
    .map((i) => i * document.documentElement.clientHeight);
  const clipPath = useTransform(value, input, [
    'polygon(0% 0%, 0% 95%, 100% 100%, 100% 5%)',
    'polygon(0% 0%, 0% 95%, 100% 100%, 100% 8.3%)',
    'polygon(0% 0%, 0% 95%, 100% 100%, 100% 8.3%)',
    'polygon(0% 0%, 0% 91.7%, 100% 100%, 100% 8.3%)',
  ]);
  const y = useTransform(value, input, ['100vh', '-10vh', '-10vh', '-120vh']);
  const bgCloudRight = {
    x: useTransform(value, input, isMobile ? [
      '200vw',
      '200vw',
      '300vw',
      '300vw',
    ] : [
      '0vw',
      '0vw',
        '80vh',
        '80vh',
    ]),
    // scale: useTransform(value, input, ['1', '1', '1.027', '1.027']),
  };
  const middleLeftNew = {
    x: useTransform(value, input, isMobile ? [
      '-15vw',
      '-15vw',
      '-100vw',
      '-100vw',
    ] : [
      '-5vh',
      '-5vh',
      '-50vh',
      '-50vh',
    ]),

    // scale: useTransform(value, input, ['1', '1', '1.0525', '1.0525']),
  };

  return {
    clipPath,
    y,
    bgCloudRight,
    middleLeftNew,
  };
}

export const stepCount = 3;

export const Cut6 = ({ scrollY, beginStep, step, isMobile }: SceneProps) => {
  const {
    clipPath,
    y,
    bgCloudRight: bgCloudRightStyle,
    middleLeftNew: middleLeftNewStyle,
  } = useParallax(scrollY, beginStep, stepCount + 1, isMobile);

  return (
    <SharedS.Container style={{ y, clipPath }}>
      <SharedS.BgWrapper>
        <PreloadImage
          imageSrc={bg}
          alt=""
          style={{
            width: '100vw',
            transformOrigin: 'left top',
          }}
        />
        <PreloadImage
          shouldContain
          imageSrc={bgCloudRight}
          alt=""
          style={{
            width: 'auto',
            height: '120vh',
            right: '0',
            left: 'initial',
            // transformOrigin: 'left top',
            ...bgCloudRightStyle,
          }}
        />
        {!isMobile && Array.from({ length: 1 }).map((_, i) => (
          <S.Fall key={`fall-${i}`} shouldContain imageSrc={fall} alt="" />
        ))}
        {!isMobile && Array.from({ length: 1 }).map((_, i) => (
          <S.Fall2 key={`fall2-${i}`} shouldContain imageSrc={fall} alt="" />
        ))}
        {!isMobile && Array.from({ length: 1 }).map((_, i) => (
          <S.Fall3 key={`fall3-${i}`} shouldContain imageSrc={fall} alt="" />
        ))}
        <PreloadImage
          shouldContain
          imageSrc={middleLeftNew}
          alt=""
          style={{
            width: 'auto',
            height: '100vh',
            left: '0',
            zIndex: 1,
            ...middleLeftNewStyle,
            ...(isMobile && {
              top: '35vh',
            })
          }}
        />
        <PreloadImage
          shouldContain
          imageSrc={light}
          alt="bbb"
          style={{
            width: '157.9vw',
            height: 'auto',
            transformOrigin: 'center center',
          }}
        />
        {/* <S.Light /> */}
        <S.Angel />
      </SharedS.BgWrapper>
      <S.ContentWrapper>
        <SharedS.Title src={title} className={step === 12 || step === 13 ? 'contentLoaded' : ''} />
        <SharedS.Content className={step === 12 || step === 13 ? 'contentLoaded' : ''}>
          Desolate Beast's ferocity gravely injured celestial gods, crumbling
          the Gate and plunging the Deity Continent into chaos.
        </SharedS.Content>
      </S.ContentWrapper>
    </SharedS.Container>
  );
};

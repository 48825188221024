import React, { useState } from "react";

import { WalletState } from "../components/Wallet/types";

export const WalletContext = React.createContext({
  isWalletModalOpen: false,
  handleWalletModalOpen: (isOpen: boolean) => { },
  wallet: {},
  setWallet: (wallet: WalletState): void => { }, // Update the type definition of setWallet
});

export const WalletContextProvider = (props: any) => {

  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);
  const [walletData, setWalletData] = useState<WalletState>({ connected: false });

  const handleWalletModalOpen = (isOpen: boolean) => {
    setIsWalletModalOpen(isOpen)
  };

  const setWallet = (wallet: WalletState) => {
    setWalletData(wallet)
  }

  return (
    <WalletContext.Provider
      value={{ isWalletModalOpen, handleWalletModalOpen, wallet: walletData, setWallet }}
    >
      {props.children}
    </WalletContext.Provider>
  );
};


import { type MotionValue, useTransform } from 'framer-motion';

import { PreloadImage } from '../PreloadImage/PreloadImage';
import * as SharedS from '../shared/styles';
import { SceneProps } from '../shared/types';
import ppl from './images/004-2.png';
import bg from './images/bg@0.5x.png';
import light from './images/light@0.5x.png';
import mintGroupLine from './images/mintGroupLine.svg';
import mintGroupText from './images/mintGroupText.svg';
import title from './images/title.svg';
import * as S from './styles';

function useParallax(value: MotionValue<number>, begin: number, count: number, isMobile: boolean) {
  const input = Array.from({ length: count })
    .map((_, i) => i + begin)
    .map((i) => i * document.documentElement.clientHeight);
  const clipPath = useTransform(value, input, [
    'polygon(0% 0%, 0% 95%, 100% 100%, 100% 5%)',
    'polygon(0% 0%, 0% 95%, 100% 100%, 100% 8.3%)',
    'polygon(0% 0%, 0% 95%, 100% 100%, 100% 8.3%)',
    'polygon(0% 0%, 0% 91.7%, 100% 100%, 100% 8.3%)',
  ]);
  const y = useTransform(value, input, ['100vh', '-10vh', '-10vh', '-120vh']);
  const light = {
    x: useTransform(value, input, ['53.96vw', '53.96vw', '41.57vw', '41.57vw']),
    y: useTransform(value, input, ['57.75vh', '57.75vh', '53.75vh', '53.75vh']),
    rotate: useTransform(value, input, [
      '-47.972deg',
      '-47.972deg',
      '0deg',
      '0deg',
    ]),
    scale: useTransform(value, input, ['0.358', '0.358', '1', '1']),
    opacity: useTransform(value, input, ['0', '0', '1', '1']),
  };
  const ppl = {
    x: useTransform(value, input, ['0', '0', '-60vw', '-60vw']),
    y: useTransform(value, input, ['10vh', '10vh', '15vh', '15vh']),
    scale: useTransform(value, input, ['1', '1', '1.4', '1.4']),
  }
  const mint = {
    opacity: useTransform(value, input, ['0', '0', '1', '1']),
  }
  return {
    clipPath,
    y,
    light,
    ppl,
    mint
  };
}

export const stepCount = 3;

export const Cut4 = ({ scrollY, beginStep, step, isMobile }: SceneProps) => {
  const {
    clipPath,
    y,
    light: lightStyle,
    ppl: pplStyle,
    mint: mintStyle
  } = useParallax(scrollY, beginStep, stepCount + 1, isMobile);

  const handleMintButton = () => {
    window.location.href = '/mint';
    // window.location.href = 'http://15.164.178.103:20080/';
    // window.location.href = '/download';
  };

  return (
    <SharedS.Container style={{ y, clipPath }}>
      <SharedS.BgWrapper>
        <S.Bg />
        <PreloadImage
          shouldContain
          alt=''
          className='cut5BoyLightning'
          imageSrc={ppl}
          align="bottom"
          style={{
            height: '150vh',
            transformOrigin: 'left bottom',
            x: '0',
            top: '0',
            ...(isMobile && {
              left: '-50vw',
              top: '15vh',
              width: '200vw',
              ...pplStyle
            })
          }}
        />
        {!isMobile && <PreloadImage
          shouldContain
          imageSrc={light}
          alt=""
          style={{
            top: '-30vh',
            left: '-50vw',
            width: '100%',
            height: '100%',
            transformOrigin: 'center center',
            ...lightStyle,
          }}
        />}
        {!isMobile && <S.Light />}
        {!isMobile && <S.MintGroupText src={mintGroupText} />}
        {!isMobile && <S.MintGroupText src={mintGroupLine} />}
        <S.Mint onClick={handleMintButton} style={{
          ...(isMobile && { ...mintStyle })
        }} />
      </SharedS.BgWrapper>
    </SharedS.Container>
  );
};

import { motion } from 'framer-motion';
import { media } from 'styled-bootstrap-grid';
import styled, { keyframes } from 'styled-components';

import { twinkling } from '../shared/animations';
import * as SharedS from '../shared/styles';
import bg from './images/bg@0.5x.png';

const carousel = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
`;

export const Bg = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 480vw;
  height: 120vh;
  background-image: url(${bg});
  background-size: contain;
  background-repeat: repeat-x;
  animation: ${carousel} 30s linear infinite;
  ${media.max.lg`
    height: 140vh;
    width: 680vw;
  `}
`;

export const Light = styled.div`
  position: absolute;
  top: 10vh;
  left: 8vw;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-image: radial-gradient(
    80% 80% at 50% 50%,
    rgba(109, 253, 255, 0.8) 0%,
    rgba(109, 253, 255, 0.3) 18.75%,
    rgba(109, 253, 255, 0) 60%
  );
  // background-color: rgba(109, 253, 255, 0.08);
  animation: ${twinkling} 2s ease-in-out infinite;
`;

export const MintGroupText = styled(motion.img)`
  // margin: 2vh 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 15vh;
  left: 0;
  // background-color: rgba(255, 255, 255, 0.1);
  opacity: 1;
`;

export const Mint = styled(SharedS.Attack).attrs<{ action: string }>({
  action: 'Mint',
  // action: 'Download'
})`
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% + 23vw);
  ${media.max.lg`
    top: calc(50% - 200px);
    left: calc(50% - 50px);
  `}
`;
import { media } from 'styled-bootstrap-grid';
import styled, { keyframes } from 'styled-components';

import { PreloadImage } from '../PreloadImage/PreloadImage';
// import { twinkling } from '../shared/animations';
import * as SharedS from '../shared/styles';

export const ContentWrapper = styled(SharedS.ContentWrapper)`
  text-align: left;
  left: 50%;
  top: 32vh;
  height: 68.75vh;
  width: 88%;
  max-width: initial;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  & > ${SharedS.Content} {
    align-self: flex-end;
    max-width: 600px;
  }
  ${media.max.lg`
    top: 40vh;
    height: 29.75vh;
  `}
`;

const twinkling = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;

export const Light = styled(PreloadImage)`
  width: auto;
  height: 132vh;
  left: 50%;
  transform: translateX(-50%);
  animation: ${twinkling} 4s infinite linear;
`;

export const Discord = styled(SharedS.LinkButton)`
  position: absolute;
  top: calc(100% - 50px - 16vh);
  height: 80px;
  left: calc(25% - 150px);
`;
export const Twitter = styled(SharedS.LinkButton)`
  position: absolute;
  top: calc(100% - 50px - 16vh);
  height: 80px;
  left: calc(75% - 150px);
`;

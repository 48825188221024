import './App.css';

// import '@solana/wallet-adapter-react-ui/styles.css'
// import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
// import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
// import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
// import {
//   LedgerWalletAdapter,
//   PhantomWalletAdapter,
//   SafePalWalletAdapter,
//   SolflareWalletAdapter
// } from "@solana/wallet-adapter-wallets";
// import { clusterApiUrl } from '@solana/web3.js';
import { useMouse } from '@uidotdev/usehooks';
import { useInView, useMotionValueEvent, useScroll } from 'framer-motion';
import React, { useCallback, useEffect, useMemo, useReducer } from 'react';

import { Cut1, stepCount as cut1StepCount } from './components/Cut1/Cut1';
import { Cut2, stepCount as cut2StepCount } from './components/Cut2/Cut2';
import { Cut3, stepCount as cut3StepCount } from './components/Cut3/Cut3';
import { Cut4, stepCount as cut4StepCount } from './components/Cut4/Cut4';
import { Cut5, stepCount as cut5StepCount } from './components/Cut5/Cut5';
import { Cut6, stepCount as cut6StepCount } from './components/Cut6/Cut6';
import { Cut7, stepCount as cut7StepCount } from './components/Cut7/Cut7';
import { Cut8, stepCount as cut8StepCount } from './components/Cut8/Cut8';
import { Frame } from './components/Frame/Frame';
import { Hero, stepCount as heroStepCount } from './components/HeroNext/Hero';
import { Mint } from './components/Mint/Mint';
import { Viewblock } from './components/Viewblock/Viewblock';
import { WalletContextProvider } from './content/WalletContextProvider';
import * as S from './styles';

const steps = [
  heroStepCount,
  cut1StepCount,
  cut2StepCount,
  cut3StepCount,
  cut4StepCount,
  cut5StepCount,
  cut6StepCount,
  cut7StepCount,
  cut8StepCount,
];

const totalStepCount = steps.reduce((total, current) => total + current - 1, 1);

const beginSteps = steps.reduce<number[]>(
  (accumulator, _, index) => [
    ...accumulator,
    index === 0
      ? 0
      : accumulator[accumulator.length - 1] + steps[accumulator.length - 1] - 1,
  ],
  [],
);

const Scene = ({
  children,
  page,
  currentPage,
}: {
  children: React.ReactNode;
  page: number;
  currentPage: number;
}) => <>{page - 1 <= currentPage && currentPage <= page + 1 && children}</>;

const Scenes = (props: { children: React.ReactNode; currentPage: number }) => (
  <>
    {React.Children.map(props.children, (child, index) => (
      <Scene page={index} currentPage={props.currentPage}>
        {child}
      </Scene>
    ))}
  </>
);

const useCursorDirection = (): ['left' | 'right', 'top' | 'bottom'] => {
  const [mouse] = useMouse();
  const { clientWidth, clientHeight } = document.documentElement;
  const hCenter = clientWidth / 2;
  const vCenter = clientHeight / 2;
  const mouseY = mouse.y % clientHeight;
  return [
    mouse.x < hCenter ? 'left' : 'right',
    mouseY < vCenter ? 'top' : 'bottom',
  ];
};

type AppReducerState = {
  readyToStart: boolean;
  started: boolean;
  doorBroken: boolean;
  boyFalling: boolean;
  boyFallen: boolean;
};

const initialSate: AppReducerState = {
  readyToStart: false,
  started: false,
  doorBroken: false,
  boyFalling: false,
  boyFallen: false,
};

const appReducer = (
  state: AppReducerState,
  action:
    | 'READY_TO_START'
    | 'START'
    | 'DOOR_BROKEN'
    | 'BOY_FALLEN'
    | 'BOY_FALLING'
    | 'RESET',
) => {
  switch (action) {
    case 'READY_TO_START':
      return { ...state, readyToStart: true };
    case 'START':
      return { ...state, started: true };
    case 'DOOR_BROKEN':
      return { ...state, doorBroken: true };
    case 'BOY_FALLING':
      return { ...state, boyFalling: true };
    case 'BOY_FALLEN':
      return { ...state, boyFallen: true };
    case 'RESET':
      return { ...initialSate, started: true };
    default:
      return state;
  }
};

type InViewHandler = (targetStep: number) => void;

const Section = ({
  step,
  onInView,
  setStep
}: {
  step: number;
  onInView?: InViewHandler;
    setStep: any;
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const isInView = useInView(ref);

  useEffect(() => {
    if (isInView) {
      console.log('in view', step);
      onInView?.(step);
      setStep(Number(step));
    }
  }, [isInView, step, onInView]);

  return (
    <S.Section>
      <S.Center ref={ref} />
    </S.Section>
  );
};

function App() {
  const [pageNumber, setPageNumber] = React.useState(0);
  const [step, setStep] = React.useState(0);
  const { scrollY } = useScroll();
  const [state, dispatch] = useReducer(appReducer, initialSate);
  const handleDoorClick = useCallback(() => {
    dispatch('DOOR_BROKEN');
  }, [dispatch]);
  const [cut2PageReady, setCut2PageReady] = React.useState(false);
  const [viewBlock, setViewBlock] = React.useState(false);
  const [mintPage, setMintPage] = React.useState(window.location.pathname === '/mint');
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 990)
  // const directions = useCursorDirection();
  const handleInView = useCallback(
    (step: number) => {
      if (step === 1) {
        setTimeout(() => dispatch('READY_TO_START'), 500);
      }
      if (step === 14) {
        // cut7 second step
        setTimeout(() => dispatch('BOY_FALLING'), 100);
      }
      if (step === 13) {
        // cut7 first step
        // remove this section before on production
        dispatch('RESET');
      }
    },
    [dispatch],
  );
  useEffect(() => {
    if (!state.started) {
      if (pageNumber === 1) {
        setTimeout(() => {
          dispatch('START');
        }, 1500);
      } else if (pageNumber >= 2) {
        dispatch('START');
      }
    }
  }, [pageNumber]);
  const handleBoyClick = useCallback(() => {
    dispatch('BOY_FALLEN');
  }, [dispatch]);
  const handleStart = useCallback(() => {
    !isMobile && window?.document.getElementById("sound").play();
    dispatch('START');
  }, [dispatch]);

  useMotionValueEvent(scrollY, 'change', (latest) => {
    const x = latest / document.documentElement.clientHeight;
    // console.log(x, beginSteps)
    // if (x >= 4 && x <= 5.0025) {
    //   setCut2PageReady(true);
    // } else {
    //   setCut2PageReady(false);
    // }
    for (let i = beginSteps.length - 1; i >= 0; i--) {
      if (x > beginSteps[i]) {
        // console.log('pageNumber', i, step);
        setPageNumber(i);
        break;
      }
    }
  });

  const resize = useCallback(() => {
    if (mintPage) return
    const propotion = window.innerWidth / window.innerHeight;
    if (1 || propotion < 2.2) {
      console.log('resize ok', propotion)
      setViewBlock(false)
      setPageNumber(0);
    } else {
      window.scrollTo({ top: 0 });
      console.log('resize fail', propotion);
      setViewBlock(true)
    }
    setIsMobile(window.innerWidth <= 990)
  }, [])

  useEffect(() => {
    window.onresize = resize;
    resize()
  }, [])

  // const rpcHost = useMemo(() => clusterApiUrl('devnet'), []);
  // const network = useMemo(() => WalletAdapterNetwork.Devnet, []);
  // const endpoint = useMemo(() => rpcHost, []);

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
  // Only the wallets you configure here will be compiled into your application, and only the dependencies
  // of wallets that your users connect to will be loaded.
  // const wallets = useMemo(
  //   () => [
  //     new LedgerWalletAdapter(),
  //     new PhantomWalletAdapter(),
  //     new SafePalWalletAdapter(),
  //     new SolflareWalletAdapter({ network })
  //   ],
  //   []
  // );
  return (
    // <ConnectionProvider endpoint={endpoint}>
    //   <WalletProvider wallets={wallets} autoConnect={true}>
    //     <WalletModalProvider>
          <WalletContextProvider>
            <div className="App">
              {!viewBlock && !mintPage && Array.from({ length: state.started ? totalStepCount : 2 }).map(
                (_, i) => (
                  <Section key={i} step={i} onInView={handleInView} setStep={setStep} />
                ),
              )}
              {viewBlock ? <Viewblock /> : mintPage ? <Mint /> : <Scenes currentPage={pageNumber}>
                <Hero
                  scrollY={scrollY}
                  beginStep={beginSteps[0]}
                  isReadyToStart={state.readyToStart}
                  isStarted={state.started}
                  onClickStart={handleStart}
                  step={step}
                  isMobile={isMobile}
                />
                <Cut1 scrollY={scrollY} beginStep={beginSteps[1]}
                  isMobile={isMobile}
                  step={step} />
                <Cut2 scrollY={scrollY} beginStep={beginSteps[2]} cut2PageReady={cut2PageReady}
                  isMobile={isMobile}
                  step={step} />
                <Cut3 scrollY={scrollY} beginStep={beginSteps[3]}
                  isMobile={isMobile}
                  step={step} />
                <Cut4 scrollY={scrollY} beginStep={beginSteps[4]}
                  isMobile={isMobile}
                  step={step} />
                <Cut5
                  isMobile={isMobile}
                  scrollY={scrollY}
                  beginStep={beginSteps[5]}
                  isDoorBroken={state.doorBroken}
                  onDoorClick={handleDoorClick}
                  step={step}
                />
                <Cut6
                  isMobile={isMobile}
                  scrollY={scrollY}
                  beginStep={beginSteps[6]}
                  // directions={directions}
                  step={step}
                />
                <Cut7
                  isMobile={isMobile}
                  scrollY={scrollY}
                  beginStep={beginSteps[7]}
                  isBoyFalling={state.boyFalling}
                  isBoyFallen={state.boyFallen}
                  onBoyClick={handleBoyClick}
                  step={step}
                />
                <Cut8 scrollY={scrollY} beginStep={beginSteps[8]}
                  isMobile={isMobile}
                  step={step} />
              </Scenes>
              }
              <Frame step={step} viewBlock={viewBlock} mintPage={mintPage} />
            </div>
          </WalletContextProvider>
    //     </WalletModalProvider>
    //   </WalletProvider>
    // </ConnectionProvider>
  );
}

export default App;

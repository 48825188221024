import { motion } from 'framer-motion';
import { media } from 'styled-bootstrap-grid';
import styled, { css, keyframes } from 'styled-components';

import { PreloadImage } from '../PreloadImage/PreloadImage';
import boy from './images/boy@0.5x.png';

export const explosionReverse = keyframes`
  0% {
    opacity: 0.33;
    transform: translateX(-50%) translateY(-50%) scale(3.33) rotate(-9deg);
  }

  75% {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%) scale(1) rotate(0deg);
  }

  100% {
    opacity: 0;
    transform: translateX(-50%) translateY(-50%) scale(0.33) rotate(3deg);
  }
`;

const explosionReverseDuration = 4;

export const Dust = styled(PreloadImage)`
  width: auto;
  height: 147.5vh;
  transform-origin: center center;
  top: 50%;
  left: 50%;
  animation: ${explosionReverse} ${explosionReverseDuration}s linear infinite;
  opacity: 0;

  & + & {
    animation-delay: ${explosionReverseDuration / 2}s;
  }
`;

export const fallingMixin = css`
  transition-duration: 0.5s;
  transform: translateX(-50%) translateY(-50%) scale(0.66) rotate(90deg);
`;

export const fallenMixin = css`
  transition-duration: 1.5s;
  transform: translateX(-50%) translateY(-50%) scale(0.1) rotate(270deg);
  opacity: 0;
`;

export const Boy = styled(motion.div)<{
  isFalling: boolean;
  isFallen: boolean;
}>`
  pointer-events: auto;
  position: absolute;
  width: 92.75vh;
  height: 49.75vh;
  transform-origin: center center;
  top: 50%;
  left: 50%;
  background-image: url(${boy});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  transform: translateX(-50%) translateY(-50%) scale(1) rotate(0deg);
  opacity: 1;
  transition-property: transform, opacity;
  transition-timing-function: ease-in;
  ${({ isFalling, isFallen }) => isFalling && !isFallen && fallingMixin}
  ${({ isFallen }) => isFallen && fallenMixin}
`;

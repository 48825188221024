import { motion } from 'framer-motion';
import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';

import { PreloadImage } from '../PreloadImage/PreloadImage';
import * as Shared from '../shared/styles';

export const Bg = styled(PreloadImage)`
  width: 150vw;
  height: 91vw;
`;

export const Door = styled(PreloadImage)`
  width: 68vw;
  height: auto;
`;

export const Photon = styled(motion.div)`
  position: absolute;
`;

export const ContentWrapper = styled(Shared.ContentWrapper)`
  top: 29vh;
`;

import { motion, type MotionValue, useTransform } from 'framer-motion';
import { useEffect, useRef } from 'react';

import { PreloadImage } from '../PreloadImage/PreloadImage';
import * as SharedS from '../shared/styles';
import { SceneProps } from '../shared/types';
import bg from './images/bg@0.5x.png';
import cloudLeft from './images/cloud(left)@0.5x.png';
import cloudRight from './images/cloud(right)@0.5x@0.5x.png';
import cloudUnmoved from './images/clound(unmove)@0.5x.png';
import door from './images/door@0.5x.png';
import title from './images/title.svg';
import * as S from './styles';

function useParallax(value: MotionValue<number>, begin: number, count: number, isMobile: boolean) {
  const input = Array.from({ length: count })
    .map((_, i) => i + begin)
    .map((i) => i * document.documentElement.clientHeight);
  const clipPath = useTransform(value, input, [
    'polygon(0% 0%, 0% 95%, 100% 100%, 100% 5%)',
    'polygon(0% 0%, 0% 95%, 100% 100%, 100% 8.3%)',
    'polygon(0% 0%, 0% 95%, 100% 100%, 100% 8.3%)',
    'polygon(0% 0%, 0% 91.7%, 100% 100%, 100% 8.3%)',
  ]);
  const y = useTransform(value, input, ['100vh', '-10vh', '-10vh', '-120vh']);
  const bgY = useTransform(value, input, ['-16vh', '-16vh', '-10vh', '-10vh']);
  const doorY = useTransform(value, input, isMobile ? [
    '35vh',
    '35vh',
    '18vh',
    '18vh',
  ] : [
    '38.125vh',
    '38.125vh',
    '22.625vh',
    '22.625vh',
  ]);
  const cloudScale = useTransform(value, input, isMobile ? ['4.5', '4.5', '5', '5'] : ['1', '1', '1.412', '1.412']);
  // cloud unmoved
  const cloudUnmoved = {
    scale: cloudScale,
  };
  // cloud left
  const cloudLeft = {
    x: useTransform(value, input, isMobile ? ['-60vw', '-60vw', '-110vw', '-110vw'] : ['-2vw', '-2vw', '-5.65vw', '-5.65vw']),
    scale: cloudScale,
  };
  // cloud right
  const cloudRight = {
    x: useTransform(value, input, isMobile ? ['20vw', '20vw', '60vw', '60vw'] : ['0', '0', '4.32vw', '4.32vw']),
    scale: cloudScale,
  };
  return {
    clipPath,
    y,
    bgY,
    doorY,
    cloudUnmoved,
    cloudLeft,
    cloudRight,
  };
}

export const stepCount = 3;

export const Cut1 = ({ scrollY, beginStep, step, isMobile }: SceneProps) => {
  const {
    clipPath,
    y,
    bgY,
    doorY,
    cloudUnmoved: cloudUnmovedStyle,
    cloudLeft: cloudLeftStyle,
    cloudRight: cloudRightStyle,
  } = useParallax(scrollY, beginStep, stepCount + 1, isMobile);

  const photonRef: any = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const numSnowflakes = 100; // 光子數量
    photonRef.current.innerHTML = '';
    for (let i = 0; i < numSnowflakes; i++) {
      const snowflake = document.createElement('div');
      snowflake.className = 'snowflake';
      photonRef.current?.appendChild(snowflake);

      // 隨機生成光子的初始位置和延遲時間
      const initialX = Math.random() * 300;
      const initialY = Math.random() * 900;
      const delay = Math.random() * 10; // 隨機生成延遲時間，單位秒

      snowflake.style.left = `${initialX}px`;
      snowflake.style.top = `${initialY}px`;

      // 延遲出現
      snowflake.style.animationDelay = `-${delay}s`;
    }
  }, []);

  return (
    <SharedS.Container style={{ y, clipPath }}>
      <SharedS.BgWrapper>
        <PreloadImage
          imageSrc={bg}
          alt=""
          style={{
            width: '100vw',
            ...(isMobile && {
              top: '-10vh',
            })
          }}
        />
        <PreloadImage
          shouldContain
          imageSrc={door}
          alt=""
          style={{
            // width: 'auto',
            height: '120vh',
            y: doorY,
            ...(isMobile && {
              scale: '1.05',
            })
          }}
        />
        <S.Photon
          style={{
            top: '50vh',
            y: bgY,
            x: '50vw',
          }}
          ref={photonRef}
        />
        {!isMobile && <PreloadImage
          shouldContain
          imageSrc={cloudUnmoved}
          alt=""
          align="bottom"
          style={{
            width: '42.5vw',
            height: 'auto',
            x: '47.5vw',
            transformOrigin: 'left bottom',
            ...cloudUnmovedStyle,
          }}
        />}
        <PreloadImage
          shouldContain
          imageSrc={cloudLeft}
          alt=""
          align="bottom"
          style={{
            width: '60.5vw',
            height: 'auto',
            transformOrigin: 'left bottom',
            ...cloudLeftStyle,
          }}
        />
        <PreloadImage
          shouldContain
          imageSrc={cloudRight}
          alt=""
          align="bottom"
          justify="right"
          style={{
            width: '36vw',
            height: 'auto',
            let: 0,
            transformOrigin: 'right bottom',
            ...cloudRightStyle,
          }}
        />
      </SharedS.BgWrapper>
      <S.ContentWrapper style={{
        x: '-50%',
        opacity: 1,
        y: '-10px'
      }}>
        <SharedS.Title src={title} className={step === 2 || step === 3 ? 'contentLoaded' : ''} />
        <SharedS.Content className={step === 2 || step === 3 ? 'contentLoaded' : ''}>
          To curb beast rampages, Parades and gods built the Gate of Clouds in
          the Glazed Organization, shielding humanity. Freed from threat,
          settlements thrived, infusing life into the Divine Realm for
          uninterrupted progress.
        </SharedS.Content>
      </S.ContentWrapper>
    </SharedS.Container>
  );
};

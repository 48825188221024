import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export interface ImageProps {
  isLoaded: boolean;
  shouldContain: boolean;
  align?: 'top' | 'bottom';
  justify?: 'left' | 'right';
}

const alignMixin = css<Pick<ImageProps, 'align'>>`
  ${(props) =>
    props.align === 'bottom'
      ? css`
          bottom: 0;
        `
      : css`
          top: 0;
        `}
`;

const justifyMixin = css<Pick<ImageProps, 'justify'>>`
  ${(props) =>
    props.justify === 'right'
      ? css`
          right: 0;
        `
      : css`
          left: 0;
        `}
`;

export const Image = styled(motion.img)<ImageProps>`
  position: absolute;
  ${alignMixin}
  ${justifyMixin}
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.45s;
  user-select: none;
  pointer-events: none;

  ${(props) =>
    props.shouldContain &&
    css`
      object-fit: contain;
    `}

  ${(props) =>
    props.isLoaded &&
    css`
      opacity: 1;
    `}
`;

import { motion } from 'framer-motion';
import { media } from 'styled-bootstrap-grid';
import styled, { keyframes } from 'styled-components';

import { PreloadImage } from '../PreloadImage/PreloadImage';
import { twinkling } from '../shared/animations';
import * as Shared from '../shared/styles';

export const BigMonsterWrapper = styled(motion.div)`
  position: absolute;
`;

export const Eyes = styled(PreloadImage)`
  animation: ${twinkling} 3s ease-in-out infinite;

  & + & {
    animation-duration: 6s;
  }
`;

export const RightSection = styled(motion.div)`
  position: absolute;
  width: 67.22vw;
  height: 111.375vh;
  top: -5vh;
  z-index: 1;
`;

export const LeftSection = styled(motion.div)`
  position: absolute;
  height: 111.375vh;
  width: 65.07vw;
  bottom: 0;
  ${media.max.lg`
    z-index: 1;
    width: 257vw;
    left: -8vw;
    top: 20vh;
  `}
`;

export const FrontLeft = styled(motion.div)`
  position: absolute;
  width: 57.15vw;
  height: 42.25vh;
  bottom: 0;
`;

const patrol1 = keyframes`
  0%, 100% {
    transform: translateX(-2vw) translateY(2vh);
  }

  50% {
    transform: translateX(2vw) translateY(-2vh) scale(0.9);
  }
`;

export const MoveOn = styled.div`
  // background-color: rgba(255, 0, 0, 0.5);
  content: '';
  left: 0;
  top: calc(50% - 15vh);
  width: 15vw;
  height: 20vh;
  // background-color: rgba(0, 0, 0, .2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-family: 'Roboto Mono';
  line-height: 21px;
  color: #fff;
  cursor: pointer;
  z-index: 1;
  position: absolute;
  cursor: pointer;
`
export const RMonster60 = styled.div`
  cursor: pointer;
  z-index: 2;
  pointer-events: auto;
  width: 18vw;
  height: 35vh;
  top: 65vh;
  left: 45vw;
  position: absolute;
`
export const RMonster6 = styled(PreloadImage)`
  animation: ${patrol1} 6s ease-in-out infinite;

  position: initial;
  pointer-events: auto;
  cursor: pointer;
  z-index: 2;
  width: 35vh;
  height: 35vh;
`;

const patrol2 = keyframes`
  0%, 100% {
    transform: translateX(-4vw) translateY(-1vh);
  }

  25%, 75% {
    transform: translateX(0vw) translateY(0vh);
  }

  50% {
    transform: translateX(4vw) translateY(-1vh);
  }
`;

export const RMonster70 = styled.div`
  cursor: pointer;
  z-index: 2;
  pointer-events: auto;
  width: 15vw;
  height: 28vh;
  top: 76vh;
  left: 24vw;
  position: absolute;
`
export const RMonster7 = styled(PreloadImage)`
  animation: ${patrol2} 10s linear infinite;
  position: initial;
  pointer-events: auto;
  cursor: pointer;
  z-index: 2;
  width: 15vw;
  height: 35vh;
`;

export const ContentWrapper = styled(Shared.ContentWrapper)`
  top: 32vh;
  height: 68.75vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  left: calc(6% - 10px);
  text-align: left;
  max-width: 504px;
  ${media.max.lg`
    top: 24vh;
    height: 29.75vh;
  `}
`;

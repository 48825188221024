import { type MotionValue, useTransform } from 'framer-motion';
import { useEffect, useState } from 'react';

import { PreloadImage } from '../PreloadImage/PreloadImage';
import * as SharedS from '../shared/styles';
import { SceneProps } from '../shared/types';
import bg from './images/bg@0.5x@0.5x.png';
import fragile from './images/fragile@0.5x.png';
import stormBase from './images/storm-base@0.5x.png';
import stormLeft from './images/storm-left@0.5x.png';
import stormRight from './images/storm-right@0.5x.png';
import title from './images/title.svg';
import * as S from './styles';

function useParallax(value: MotionValue<number>, begin: number, count: number, isMobile: boolean) {
  const input = Array.from({ length: count })
    .map((_, i) => i + begin)
    .map((i) => i * document.documentElement.clientHeight);
  const clipPath = useTransform(value, input, [
    'polygon(0% 0%, 0% 95%, 100% 100%, 100% 5%)',
    'polygon(0% 0%, 0% 95%, 100% 100%, 100% 8.3%)',
    'polygon(0% 0%, 0% 95%, 100% 100%, 100% 8.3%)',
    'polygon(0% 0%, 0% 91.7%, 100% 100%, 100% 8.3%)',
  ]);
  const y = useTransform(value, input, ['100vh', '-10vh', '-10vh', '-120vh']);
  const characters = {
    scale: useTransform(value, input, ['1', '1', '1.2', '1.2']),
  };
  const contentWrapper = {
    opacity: useTransform(value, input, ['0', '0', '1', '1']),
    y: useTransform(value, input, ['0px', '0px', '-10px', '-10px']),
  };
  return {
    clipPath,
    y,
    bg,
    characters,
    contentWrapper,
  };
}

export const stepCount = 3;

export const Cut2 = ({ scrollY, beginStep, cut2PageReady, step, isMobile }: SceneProps & { cut2PageReady: boolean }) => {
  const {
    clipPath,
    y,
    characters: charactersStyle,
  } = useParallax(scrollY, beginStep, stepCount + 1, isMobile);

  const [moveOnZoomIn, setMoveOnZoomIn] = useState([false, false, false, false])
  const handleMoveOnZoomIn = (i: number) => {
    const data = [false, false, false, false]
    data[i] = true
    console.log('move', i, data)
    setMoveOnZoomIn(data)
  }

  const handleMoveOnZoomOut = () => {
    setMoveOnZoomIn([false, false, false, false])
  }

  return (
    <SharedS.Container style={{ y, clipPath, background: '#000' }}>
      <SharedS.BgWrapper>
        <PreloadImage
          imageSrc={bg}
          alt=""
          className="lightning"
          style={{
            width: '100vw',
          }}
        />
        <S.CharactersWrapper style={{ width: '114%', x: '-50%', y: '-5%', ...charactersStyle }}>
          <S.StormWrapper>
            <PreloadImage
              shouldContain
              alt=''
              imageSrc={stormBase}
              style={{
                height: '105vh',
                transformOrigin: 'left top',
                x: '9.59vw',
                y: '-4vh',
                scale: '1.104',
                width: 'auto',
                ...(isMobile && {
                  x: '-30vw',
                  y: '-10vh',
                })
              }}
            />
            {!isMobile && <S.StormInnerWrapper>
              <PreloadImage
                shouldContain
                imageSrc={stormLeft}
                alt=""
                style={{
                  width: '65.72vw',
                  height: 'auto',
                  transformOrigin: 'left top',
                  x: '17.2vw',
                  y: '10.18vh',
                  // ...stormLeftStyle,
                }}
              />
            </S.StormInnerWrapper>}
          </S.StormWrapper>
        </S.CharactersWrapper>
        {!isMobile && Array.from({ length: 2 }).map((_, i) => (
          <S.Fragile
            key={`fragile-${i}`}
            shouldContain
            imageSrc={fragile}
            alt=""
          />
        ))}
        <S.CharacterGhost className={moveOnZoomIn[0] ? 'zoomIn' : 'zoomOut'} />
        <S.CharacterCenter className={moveOnZoomIn[2] ? 'zoomIn2' : 'zoomOut2'} />
        <S.CharacterRight className={moveOnZoomIn[3] ? 'zoomIn3' : 'zoomOut3'} />
        {!isMobile && <S.CharacterEffect className={moveOnZoomIn[3] ? 'zoomIn3' : 'zoomOut3'} />}
        <S.CharacterLeft className={moveOnZoomIn[1] ? 'zoomIn' : 'zoomOut'} />
      </SharedS.BgWrapper>
      <S.ContentWrapper style={{
        x: '-50%',
        opacity: 1,
        y: '-10px'
      }}>
        <SharedS.Title src={title} className={step === 4 || step === 5 ? 'contentLoaded' : ''} />
        <SharedS.Content className={step === 4 || step === 5 ? 'contentLoaded' : ''}>
          Centuries passed as hordes of ferocious beasts invaded the Deity
          Continent. Tasked with resistance and eliminating these Fierce
          Spirits, Parades vowed to defend their land.
          <br />
          <br />
          <S.MissionText>
            THE MISSION + Stop the Fierce Spirits from breaching the Gate of
            Clouds at all costs
          </S.MissionText>
        </SharedS.Content>
      </S.ContentWrapper>
      {!isMobile && <S.MoveOn onMouseOver={() => { handleMoveOnZoomIn(0) }} onMouseLeave={handleMoveOnZoomOut} style={{
        height: '30vh', top: 'calc(50% - 20vh)', width: '20vw', left: '0',
      }} />}
      {!isMobile && <S.MoveOn onMouseOver={() => { handleMoveOnZoomIn(1) }} onMouseLeave={handleMoveOnZoomOut} style={{ left: 'calc(50% - 45vw)', top: 'calc(50% + 12vh)', height: '38vh', width: '20vw' }} />}
      {!isMobile && <S.MoveOn onMouseOver={() => { handleMoveOnZoomIn(2) }} onMouseLeave={handleMoveOnZoomOut} style={{ left: 'calc(50% - 15vw)', top: 'calc(50% + 30vh)', height: '20vh', width: '20vw' }} />}
      {!isMobile && <S.MoveOn onMouseOver={() => { handleMoveOnZoomIn(3) }} onMouseLeave={handleMoveOnZoomOut} style={{ left: 'calc(50% + 20vw)', top: 'calc(50% + 5vh)', height: '45vh', width: '30vw' }} />}
    </SharedS.Container>
  );
};

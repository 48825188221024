import { type MotionValue, useTransform } from 'framer-motion';

import { PreloadImage } from '../PreloadImage/PreloadImage';
import * as SharedS from '../shared/styles';
import { SceneProps } from '../shared/types';
import angel from './images/angel@0.5x.png';
import bg from './images/bg@0.5x.png';
import feather from './images/feather@0.5x.png';
import group2 from './images/Group2.png';
import light1 from './images/light-1@0.5x.png';
// import light3 from './images/light-3@0.5x.png';
// import light4 from './images/light-4@0.5x.png';
// import light5 from './images/light-5@0.5x.png';
// import light6 from './images/light-6@0.5x.png';
// import light7 from './images/light-7@0.5x.png';
// import light8 from './images/light-8@0.5x.png';
// import light9 from './images/light-9@0.5x.png';
import title from './images/title.svg';
import * as S from './styles';

function useParallax(value: MotionValue<number>, begin: number, count: number, isMobile: boolean) {
  const input = Array.from({ length: count })
    .map((_, i) => i + begin)
    .map((i) => i * document.documentElement.clientHeight);
  const clipPath = useTransform(value, input, [
    'polygon(0% 0%, 0% 100%, 100% 100%, 100% 5%)',
    'polygon(0% 0%, 0% 100%, 100% 100%, 100% 8.3%)',
  ]);
  const y = useTransform(value, input, ['100vh', '-10vh']);

  return {
    clipPath,
    y,
  };
}

export const stepCount = 2;

export const Cut8 = ({ scrollY, beginStep, step, isMobile }: SceneProps) => {
  const { clipPath, y } = useParallax(scrollY, beginStep, stepCount, isMobile);

  return (
    <SharedS.Container style={{ y, clipPath }}>
      <SharedS.BgWrapper >
        <PreloadImage
          imageSrc={bg}
          alt=""
          style={{
            width: '100vw',
            transformOrigin: 'center top',
          }}
        />
        <PreloadImage
          shouldContain
          imageSrc={light1}
          alt=""
          style={{
            width: 'auto',
            height: '132vh',
            left: '50%',
            x: '-50%',
            transformOrigin: '60% top',
            y: '-2.25vh',
            scale: '1.017',
            ...(isMobile && {
              left: '80%',
            }),
          }}
        />
        <S.Light shouldContain imageSrc={group2} alt="" style={{
          ...(isMobile && {
            left: '80%',
          }),
        }} />
        <PreloadImage
          shouldContain
          imageSrc={feather}
          alt=""
          style={{
            width: 'auto',
            height: '132vh',
            left: '50%',
            transformOrigin: 'center top',
            x: 'calc(-1vw - 50%)',
            ...(isMobile && {
              left: '80%',
            }),
          }}
        />
        <PreloadImage
          shouldContain
          imageSrc={angel}
          alt=""
          style={{
            width: 'auto',
            height: '132vh',
            left: '50%',
            transformOrigin: 'center top',
            x: 'calc(-2vh - 50%)',
            y: '3.75vh',
            ...(isMobile && {
              left: '80%',
            }),
          }}
        />
      </SharedS.BgWrapper>
      <S.ContentWrapper>
        <SharedS.Title src={title} className={step === 16 || step === 17 ? 'contentLoaded' : ''} />
        <SharedS.Content className={step === 16 || step === 17 ? 'contentLoaded' : ''} style={{ marginBottom: '50px' }}>
          Given a second chance, the Parade, armed with foresight, must unite
          celestial gods against the Desolate Beast, securing a peaceful future
          for the Deity Continent and rewriting destiny at Glaze's peak to save
          all life.
        </SharedS.Content>
      </S.ContentWrapper>
    </SharedS.Container>
  );
};

import styled from 'styled-components';

import { PreloadImage } from '../PreloadImage/PreloadImage';
import * as Shared from '../shared/styles';

export const Logo = styled(PreloadImage)`
  width: 80px;
  height: 80px;
  left: calc(50% - 40px);
  top: 20vh;
`;

export const ContentWrapper = styled(Shared.ContentWrapper)`
  top: calc(20vh + 80px);
`;

import React from 'react';

import * as S from './styles';
import { useMediaPreload } from './useMediaPreload';

interface Props {
  imageSrc: string;
  alt: string;
  shouldContain?: boolean;
  className?: string;
  style?: any;
}

export const PreloadImage = React.memo(
  (props: Props & Pick<S.ImageProps, 'align' | 'justify'>) => {
    const {
      shouldContain = false,
      alt,
      imageSrc,
      className,
      style,
      align,
      justify,
    } = props;

    // const { isLoaded } = useMediaPreload({ isImage: true, mediaSrc: imageSrc });
    // console.log(`render: ${imageSrc}`);

    return (
      <S.Image
        className={className}
        shouldContain={shouldContain}
        isLoaded
        src={imageSrc}
        alt={alt}
        style={style}
        align={align}
        justify={justify}
      />
    );
  },
);

import { type MotionValue, useTransform } from 'framer-motion';
import { useEffect, useState } from 'react';

import { PreloadImage } from '../PreloadImage/PreloadImage';
import * as SharedS from '../shared/styles';
import { SceneProps } from '../shared/types';
import bg from './images/bg@0.5x.png';
import cloudLeft from './images/cloud-left@0.5x.png';
import cloudRightNew from './images/cloud-right-new@0.5x.png';
import doorBroken from './images/door-broken@0.5x.png';
import doorCompleted from './images/door-completed@0.5x.png';
import theGoldenSlash from './images/theGoldenSlash-keep-png.png'
import title from './images/title.svg';
import * as S from './styles';

function useParallax(value: MotionValue<number>, begin: number, count: number, isMobile: boolean) {
  const input = Array.from({ length: count })
    .map((_, i) => i + begin)
    .map((i) => i * document.documentElement.clientHeight);
  const clipPath = useTransform(value, input, [
    'polygon(0% 0%, 0% 95%, 100% 100%, 100% 5%)',
    'polygon(0% 0%, 0% 95%, 100% 100%, 100% 8.3%)',
    'polygon(0% 0%, 0% 95%, 100% 100%, 100% 8.3%)',
    'polygon(0% 0%, 0% 91.7%, 100% 100%, 100% 8.3%)',
  ]);
  const y = useTransform(value, input, ['100vh', '-10vh', '-10vh', '-120vh']);
  const bg = {
    y: useTransform(value, input, ['-5vh', '-5vh', '0vh', '0vh']),
    scale: useTransform(value, input, ['0.986', '0.986', '1', '1']),
  };
  const door = {
    y: useTransform(value, input, isMobile ? ['25vh', '25vh', '10vh', '10vh'] : ['0vh', '0vh', '-1vh', '-1vh']),
    scale: useTransform(value, input, ['1', '1', '1.085', '1.085']),
  };

  const cloudScale = useTransform(value, input, ['1', '1', '0.94', '0.94']);
  const cloudLeft = {
    scale: cloudScale,
    x: useTransform(value, input, isMobile ? ['0vw', '0vw', '-50vw', '-50vw'] : ['0vw', '0vw', '-17.15vw', '-17.15vw']),
  };
  const cloudRightNew = {
    scale: cloudScale,
    x: useTransform(value, input, isMobile ? ['17.6vw', '17.6vw', '40vw', '40vw'] : ['17.6vw', '17.6vw', '30.42vw', '30.42vw']),
  };
  const attack = {
    opacity: useTransform(value, input, ['0', '0', '1', '1']),
  };

  return {
    clipPath,
    y,
    bg,
    door,
    cloudLeft,
    cloudRightNew,
    attack,
  };
}

export const stepCount = 3;

export const Cut5 = ({
  scrollY,
  beginStep,
  isDoorBroken,
  onDoorClick,
  step,
  isMobile
}: SceneProps & {
  isDoorBroken: boolean;
  onDoorClick: () => void;
}) => {
  const {
    clipPath,
    y,
    bg: bgStyle,
    door: doorStyle,
    cloudLeft: cloudLeftStyle,
    cloudRightNew: cloudRightNewStyle,
    attack: attackStyle,
  } = useParallax(scrollY, beginStep, stepCount + 1, isMobile);

  const [attack, setAttack] = useState(false)
  useEffect(() => {
    if (isDoorBroken) {
      setTimeout(() => {
        setAttack(true)
      }, 1000)
    }
  }, [isDoorBroken])

  return (
    <SharedS.Container style={{ y, clipPath }}>
      <SharedS.BgWrapper>
        <PreloadImage
          imageSrc={bg}
          alt=""
          style={{
            width: '100vw',
            transformOrigin: 'center center',
            ...bgStyle,
          }}
        />
        <PreloadImage
          shouldContain
          imageSrc={theGoldenSlash}
          style={{
            visibility: isDoorBroken && !attack ? 'initial' : 'hidden',
            width: '60%',
            x: 'calc(50% - 10vw)',
            opacity: '80%',
            rotate: "220deg",
            scale: 1.8,
            zIndex: 2,
            ...(isMobile && {
              width: '70%',
              x: 'calc(50% - 20vw)',
              y: 'calc(50% - 50vh)',
            })

          }}
          alt=""
        />
        <S.Door
          style={{
            x: 'calc(3.5vw - 50%)',
            ...doorStyle,
            ...(isMobile && {
              width: '100vw',
            })
          }}>
          <PreloadImage
            className={isDoorBroken && !attack ? 'shockwave' : ''}
            shouldContain
            imageSrc={doorCompleted}
            style={{ visibility: isDoorBroken && attack ? 'hidden' : 'initial' }}
            alt=""
          />
          <PreloadImage
            shouldContain
            imageSrc={doorBroken}
            style={{ visibility: isDoorBroken && attack ? 'initial' : 'hidden' }}
            alt=""
          />
        </S.Door>
      </SharedS.BgWrapper>
      <PreloadImage
        shouldContain
        imageSrc={cloudLeft}
        alt=""
        align="bottom"
        style={{
          width: '79.3vw',
          height: 'auto',
          transformOrigin: 'left bottom',
          y: 'calc( 50% - 35vh)',
          ...cloudLeftStyle,
          ...(isMobile && {
            y: '-5vh',
            scale: '2'
          })
        }}
      />
      <PreloadImage
        shouldContain
        imageSrc={cloudRightNew}
        alt=""
        align="bottom"
        justify="right"
        style={{
          width: '82vw',
          height: 'auto',
          transformOrigin: 'right bottom',
          top: '50%',
          y: '-50%',
          ...cloudRightNewStyle,
          ...(isMobile && {
            y: '45vh',
            scale: '1.5'
          })
        }}
      />
      <S.ContentWrapper>
        <SharedS.Title src={title} className={step === 10 || step === 11 ? 'contentLoaded' : ''} />
        <SharedS.Content className={step === 10 || step === 11 ? 'contentLoaded' : ''}>
          Despite uniting against the formidable Desolate Beast, gods and
          Parades faced ongoing menace. In the chaos, the shattered Gate of
          Clouds plunged the Deity Continent into peril
        </SharedS.Content>
      </S.ContentWrapper>
      {!isDoorBroken && <S.Attack onClick={onDoorClick} style={attackStyle} />}
    </SharedS.Container>
  );
};

import { motion } from 'framer-motion';
import { media } from 'styled-bootstrap-grid';
import styled, { keyframes } from 'styled-components';

import { PreloadImage } from '../PreloadImage/PreloadImage';

export const Container = styled.div`
  pointer-events: none;
  position: fixed;
  top: 64px;
  left: 32px;
  right: 32px;
  bottom: 32px;
  border-left: 0.5px solid rgba(255, 255, 255, 0.5);
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.5);
  border-right: 0.5px solid rgba(255, 255, 255, 0.5);
  border-top: 0px solid rgba(255, 255, 255, 0.5);
  border-radius: 24px;
  z-index: 1;
  ${media.max.lg`
    border: 0;
  `}
`;

export const Link = styled.div`
  position: absolute;
  width: 100%;
  height: 50px;
  top: calc(100% - 50px);
  border-top: 0.5px solid rgba(255, 255, 255, 0.5);
  display: flex;
  color: rgba(255, 255, 255, 1);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  pointer-events: auto;
`;

export const LinkButton = styled.span`
  flex: 1;
  cursor: pointer;
  height: 100%;
  line-height: 50px;
  // background-color: rgba(255, 255, 255, 0.1);
  &:first-child {
    border-right: 0.5px solid rgba(255, 255, 255, 0.5);
  }
`;

export const Nav = styled.nav`
  pointer-events: auto;
  position: fixed;
  top: 25px;
  left: 5%;
  display: flex;
  align-items: center;
  height: 72px;
  width: calc(90% - 2px);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
`;

export const LogoContainer = styled.div`
  width: 72px;
  padding-bottom: 72px;
  margin-right: auto;
  position: relative;
  cursor: pointer;
`;

export const Logo = styled(PreloadImage)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const NavItem = styled.div`
  padding: 16px 24px;
  font-family: 'Roboto Mono';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  color: rgba(255, 255, 255, 1);
  &:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
  }
`;

export const NavBtn = styled(NavItem)`
  border-radius: 12px;
  border: 0.5px solid rgba(255, 255, 255, 0.5);
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

export const Row = styled.div`
  display: flex;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 72px);
  border-radius: 0 0 24px 0;
`;

// Hero

export const Hero = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  padding: 32px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  transition-property: opacity;
  transition-duration: 0.3s;
`;

export const HeroDescription = styled.div`
  height: 8em;
  color: rgba(255, 255, 255, 1);
  font-family: Roboto Mono;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 21px;
  width: 620px;
  text-align: center;
  margin: 0 auto;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.3s;
`;

export const HeroLogo = styled.div`
  height: calc(100% - 8em);
  display: flex;
  margin: 0 auto;
`;

export const HeroLogotype1 = styled(motion.img)`
  position: relative;
  height: 100px;
  width: 571.42px;
  scale: 1;
  top: calc(50% - 140px);
  left: calc(50% - 285.71px);
  transition-property: scale, left, top;
  transition-duration: 1s;
`;

export const HeroLogotype2 = styled(motion.img)`
  position: relative;
  height: 100px;
  width: 175.24px;
  scale: 1;
  top: calc(50% - 30px);
  left: calc(50% - 285.71px - 380px);
  transition-property: scale, left, top;
  transition-duration: 1s;
`;

export const HeroLogotype3 = styled(motion.img)`
  position: relative;
  height: 100px;
  width: 284.76px;
  scale: 1;
  top: calc(50% + 80px);
  left: calc(50% - 285.71px - 380px - 230px);

  transition-property: scale, left, top;
  transition-duration: 1s;
`;

export const SocialIcon = styled(motion.img)`
  margin-right: 0.5em;
  width: 1em;
  top: 0.15em;
  position: relative;
`;
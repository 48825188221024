import { motion } from 'framer-motion';
import { media } from 'styled-bootstrap-grid';
import styled, { css, keyframes } from 'styled-components';

import * as Shared from '../shared/styles';
import bg from './images/bg@0.5x.png';
import bgBook1 from './images/bg-book-1@0.5x.png';
import bgBook2 from './images/bg-book-2@0.5x.png';
import bird from './images/bird@0.5x.png';
import light from './images/light@0.5x.png';
import lights1 from './images/lights/1.png';
import lights2 from './images/lights/2.png';
import lights21 from './images/lights/2-1.png';
import lights22 from './images/lights/2-2.png';
import lights3 from './images/lights/3.png';
import lights4 from './images/lights/4.png';
import tree from './images/tree@0.5x.png';
import treeBase from './images/tree-base@0.5x.png';
import treeSmoke1 from './images/tree-smoke-1@0.5x.png';
import treeSmoke2 from './images/tree-smoke-2@0.5x.png';

export const BgWrapper = styled(Shared.BgWrapper)`
  overflow: hidden;
  background-image: url(${bg});
  background-size: auto 120vh;
  background-position: center;
`;

const bgBooksAnimation = keyframes`
  0% {
    transform: translateY(calc(11.5625vh - 50%));
  }
  50% {
    transform: translateY(calc(10.5625vh - 50%));
  }
  100% {
    transform: translateY(calc(11.5625vh - 50%));
  }
`;

export const BgBooks = styled(motion.div)`
  position: absolute;
  width: 225vh;
  height: 99.125vh;
  left: calc(50% - 112.5vh);
  top: 50%;
  background-image: url(${bgBook1});
  background-size: cover;
  background-position: center;
  animation: ${bgBooksAnimation} 8s ease-in-out infinite;
  animation-delay: 1s;
  ${media.max.lg`
    left: calc(50% - 65.5vh);
    top: 55%;
    z-index: 1;
  `}
`;

export const BgBook2 = styled(BgBooks)`
  background-image: url(${bgBook2});
`;

const treeAnimation = keyframes`
  0% {
    transform: translateY(calc(-50% - 9vh));
  }
  50% {
    transform: translateY(calc(-50% - 14vh));
  }
  100% {
    transform: translateY(calc(-50% - 9vh));
  }
`;

export const TreeContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const Tree = styled(motion.div)`
  position: absolute;
  width: 119.375vh;
  height: 106.25vh;
  left: calc(50% - 59.6875vh);
  top: 50%;
  background-image: url(${tree});
  background-size: cover;
  background-position: center;
  animation: ${treeAnimation} 8s ease-in-out infinite;
  ${media.max.lg`
    width: 71.625vh;
    height: 63.75vh;
    left: calc(50% - 35.8125vh);
    top: 52%;
  `}
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-image: url(${treeBase});
    background-size: cover;
    background-position: center;
  }
`;

const treeSmokeAnimation = keyframes`
  0% {
    transform: translateX(calc(-3vh - 50%));
  }
  25% {
    transform: translateX(calc(0vh - 50%));
  }
  50% {
    transform: translateX(calc(3vh - 50%));
  }
  75% {
    transform: translateX(calc(0vh - 50%));
  }
  100% {
    transform: translateX(calc(-3vh - 50%));
  }
`;

const treeSmokeAnimation2 = keyframes`
  0% {
    transform: translateX(calc(-15vw - 50%));
    opacity: 0;
  }
  33% {
    transform: translateX(calc(-5vw - 50%));
    opacity: 1;
  }
  66% {
    transform: translateX(calc(5vw - 50%));
    opacity: 1;
  }
  100% {
    transform: translateX(calc(15vw - 50%));
    opacity: 0;
  }
`;

export const TreeSmoke = styled(motion.div)`
  position: absolute;
  width: 96.25vh;
  height: 43.75vh;
  left: 50%;
  top: 50%;
  background-size: cover;
  background-position: center;
`;

export const TreeSmoke1 = styled(TreeSmoke)`
  background-image: url(${treeSmoke1});
  animation: ${treeSmokeAnimation} 10s linear infinite;
`;

export const TreeSmoke2 = styled(TreeSmoke)`
  background-image: url(${treeSmoke2});
  animation: ${treeSmokeAnimation2} 15s linear infinite;
`;

export const Light = styled.div`
  position: absolute;
  width: 225vh;
  height: 51.875vh;
  background-image: url(${light});
  background-size: cover;
  background-position: center;
  top: 0;
  left: 50%;
  transform: translateX(calc(10vh - 50%));
  opacity: 0.7;
`;

export const Lights = styled(motion.div)`
  position: absolute;
  top: -10vh;
  background-size: contain;
  background-position: center;
`;

const light1Animation = keyframes`
  0% {
    transform: rotate(-11.925deg);
  }

  50% {
    transform: rotate(-28.43deg);
  }

  1000% {
    transform: rotate(-11.925deg);
  }
`;

export const Lights1 = styled(Lights)`
  width: 30.625vh;
  height: 51.75vh;
  left: -11vw;
  transform-origin: right top;
  transform: rotate(-11.925deg);
  background-image: url(${lights1});
  animation: ${light1Animation} 6s ease-in-out infinite;
`;

const light21Animation = keyframes`
  0% {
    transform: rotate(-18.096deg);
  }

  50% {
    transform: rotate(-5.411deg);
  }

  1000% {
    transform: rotate(-18.096deg);
  }
`;

export const Lights21 = styled(Lights)`
  width: 20vh;
  height: 22.125vh;
  left: 16.5vw;
  transform-origin: 60% top;
  transform: rotate(-18.096deg);
  background-image: url(${lights21});
  animation: ${light21Animation} 5s ease-in-out infinite;
  ${media.max.lg`
    width: 36vh;
    height: 39.825vh;
    left: -30vw;
    top: 0vh;
  `}
`;

const light22Animation = keyframes`
  0% {
    transform: rotate(-11.795deg);
  }

  50% {
    transform: rotate(-8.432deg);
  }

  1000% {
    transform: rotate(-11.795deg);
  }
`;

export const Lights22 = styled(Lights)`
  width: 11.25vh;
  height: 17.875vh;
  left: 44%;
  transform-origin: 70% top;
  transform: rotate(-11.795deg);
  background-image: url(${lights22});
  animation: ${light22Animation} 5s ease-in-out infinite;
  animation-delay: -0.5;
  ${media.max.lg`
    left: 30vw;
    top: -2vh;
  `}
`;

const light2Animation = keyframes`
  0% {
    transform: rotate(-5.275deg);
  }

  50% {
    transform: rotate(-10.727deg);
  }

  1000% {
    transform: rotate(-5.275deg);
  }
`;

export const Lights2 = styled(Lights)`
  width: 30vh;
  height: 51.875vh;
  left: 26%;
  transform-origin: 55% top;
  transform: rotate(-5.275deg);
  background-image: url(${lights2});
  animation: ${light2Animation} 6s ease-in-out infinite;
  animation-delay: -0.75;
`;

const light3Animation = keyframes`
  0% {
    transform: rotate(18.107deg);
  }

  50% {
    transform: rotate(-4.613deg);
  }

  1000% {
    transform: rotate(18.107deg);
  }
`;

export const Lights3 = styled(Lights)`
  width: 20.25vh;
  height: 28.875vh;
  right: 20%;
  transform-origin: 40% top;
  transform: rotate(18.107deg);
  background-image: url(${lights3});
  animation: ${light3Animation} 5s ease-in-out infinite;
  animation-delay: -1;
  ${media.max.lg`
    right: -20%;
    top: -5vh;
  `}
`;

const light4Animation = keyframes`
  0% {
    transform: rotate(17.035deg);
  }

  50% {
    transform: rotate(38.751deg);
  }

  1000% {
    transform: rotate(17.035deg);
  }
`;

export const Lights4 = styled(Lights)`
  width: 29.25vh;
  height: 45.5vh;
  right: -13%;
  transform-origin: 0% top;
  transform: rotate(17.035deg);
  background-image: url(${lights4});
  animation: ${light4Animation} 6s ease-in-out infinite;
  animation-delay: -1.25;
`;

export const birdFlying = keyframes`
  0% {
    opacity: 0.3;
    transform: translateX(calc(50%)) translateY(calc(-150%)) scale(0.66);
  }

  25% {
    opacity: 1;
    transform: translateX(calc(-50%)) translateY(calc(-250%)) scale(1);
  }

  50% {
    opacity: 1;
    transform: translateX(calc(-150%)) translateY(-350%) scale(1.33);
  }
  100% {
    opacity: 0;
    transform: translateX(calc(-150%)) translateY(-350%) scale(1.33);
  }
`;

export const Bird = styled.div`
  position: absolute;
  width: 29.5vh;
  height: 22.5vh;
  background-image: url(${bird});
  background-size: cover;
  background-position: center;
  top: 50%;
  left: 50%;
  opacity: 0.3;
  animation: ${birdFlying} 18s linear infinite;
  animation-delay: 6s;
`;

export const CabinetWrapper = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  ${media.max.lg`
    z-index: 1;
  `}
`;

export const Start = styled(Shared.Attack).attrs<{
  action: string;
}>({
  action: 'Start',
})`
  position: absolute;
  top: calc(70% - 10vh);
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  ${media.max.lg`
    top: 70%;
  `}
`;

export const transitionMixin = css`
  transition: opacity 1.5s;
`;

export const LogoContainer = styled.div<{
  hide: boolean;
  clickable: boolean;
}>`
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  ${transitionMixin}

  & > ${Start} {
    pointer-events: ${({ clickable, hide }) =>
    clickable && !hide ? 'auto' : 'none'};
  }
`;

export const ContentWrapper = styled(Shared.ContentWrapper) <{ show: boolean }>`
  width: 670px;
  max-width: initial;
  height: 160px;
  top: calc(50% - 10vh);
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transform: translateX(-50%) translateY(-50%);
  ${transitionMixin}
  transition-delay: 1s;
  width: 90%;
  max-width: 990px;
`;

import { Button, Icon } from 'semantic-ui-react'

import { Wallet } from '../Wallet';
import Discord from './images/discord.svg';
import logo from './images/logo.png';
import { Sound } from './Sound';
import * as S from './styles';
import Twitter from './twitter.svg';

export const Frame = ({ step, viewBlock, mintPage }) => {
  const handleHome = () => {
    if (window.location.pathname === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      window.location.href = '/';
    }
  }

  const handleClickTwitter = () => {
    window.open('https://twitter.com/DOGameSol');
  }

  const handleClickDiscord = () => {
    window.open('https://discord.com/invite/destinyofgods');
  }

  const handleMint = () => {
    window.location.href = '/mint';
  }

  const handleDown = () => {
    window.location.href = '/download';
  }
  return (
    <S.Container style={viewBlock ? { borderTopWidth: '0.5px' } : mintPage ? { border: 'initial' } : {}}>
      {!viewBlock ? <S.Nav className={!mintPage ? 'addNavAfter addNavAfter' : ''}>
        <S.LogoContainer onClick={handleHome} >
          <S.Logo imageSrc={logo} alt="Destiny Of Gods" />
        </S.LogoContainer>
        <S.NavItem className='NavItem' onClick={handleHome}>Home</S.NavItem>
        {/* <S.NavItem className='NavItem' onClick={handleDown}>Download</S.NavItem> */}
        <a href="/download" download="DestinyOfGods.apk">Download</a>
        <S.NavItem className='NavItem' onClick={handleMint}>WhitelistChecker</S.NavItem>
        {/* <Wallet /> */}
        <S.NavItem className='NavItem NavSound' >
          <Sound />
        </S.NavItem>
      </S.Nav> : null}
      {((!mintPage && step > 15) || viewBlock) && <S.Link className={(step === 16 || viewBlock) ? 'fadeIn' : 'hidden'}>
        {(step === 16 || viewBlock) ? <>
          <S.LinkButton onClick={handleClickTwitter}><S.SocialIcon src={Twitter} />Twitter</S.LinkButton>
          <S.LinkButton onClick={handleClickDiscord}><S.SocialIcon src={Discord} />Discord</S.LinkButton></> : null}
      </S.Link>}
    </S.Container>
  );
};

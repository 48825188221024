import { type MotionValue, useAnimate, useTransform } from 'framer-motion';
import { useEffect, useState } from 'react';

import { PreloadImage } from '../PreloadImage/PreloadImage';
import * as SharedS from '../shared/styles';
import { SceneProps } from '../shared/types';
import bg from './images/bg@0.5x.png';
import bigMonster from './images/big-monster@0.5x.png';
import bigMonsterEyes from './images/big-monster-eyes@0.5x.png';
import bigMonsterMist from './images/big-monster-mist@0.5x.png';
import boy from './images/boy@0.5x.png';
import frontLCloud1 from './images/front-l-cloud-1@0.5x.png';
import frontLCloud2 from './images/front-l-cloud-2@0.5x.png';
import frontRCloud from './images/front-r-cloud@0.5x.png';
import lCloud1 from './images/l-cloud-1@0.5x.png';
import lCloud2 from './images/l-cloud-2@0.5x.png';
import rCloud1 from './images/r-cloud-1@0.5x.png';
import rCloud2 from './images/r-cloud-2@0.5x.png';
import rCloud5 from './images/r-cloud-5@0.5x.png';
import rMonster3 from './images/r-monster-3@0.5x.png';
import rMonster4 from './images/r-monster-4@0.5x.png';
import rMonster6 from './images/r-monster-6@0.5x.png';
import rMonster7 from './images/r-monster-7@0.5x.png';
import title from './images/title.svg';
import titleMobile from './images/title-mobile.svg';
import * as S from './styles';

function useParallax(value: MotionValue<number>, begin: number, count: number, isMobile: boolean) {
  const input = Array.from({ length: count })
    .map((_, i) => i + begin)
    .map((i) => i * document.documentElement.clientHeight);
  const clipPath = useTransform(value, input, [
    'polygon(0% 0%, 0% 95%, 100% 100%, 100% 5%)',
    'polygon(0% 0%, 0% 95%, 100% 100%, 100% 8.3%)',
    'polygon(0% 0%, 0% 95%, 100% 100%, 100% 8.3%)',
    'polygon(0% 0%, 0% 91.7%, 100% 100%, 100% 8.3%)',
  ]);
  const y = useTransform(value, input, ['100vh', '-10vh', '-10vh', '-120vh']);
  const bigMonsterWrapper = {
    scale: useTransform(value, input, ['0.95', '0.95', '1', '1']),
    x: useTransform(value, input, ['23.19vw', '23.19vw', '21.18vw', '21.18vw']),
    y: useTransform(value, input, ['11.625vh', '11.625vh', '6vh', '6vh']),
  };
  const rightSection = {
    x: useTransform(value, input, ['32.78vw', '32.78vw', '32.08vw', '32.08vw']),
    y: useTransform(value, input, ['8.625vh', '8.625vh', '9vh', '9vh']),
  };
  const rCloud5 = {
    x: useTransform(value, input, ['0vw', '0vw', '9.02vw', '9.02vw']),
  };
  const leftSection = {
    x: useTransform(value, input, ['0vw', '0vw', '-6.94vw', '-6.94vw']),
  };
  const lCloud2 = {
    x: useTransform(value, input, ['0vw', '0vw', '-9.73vw', '-9.73vw']),
  };
  const boy = {
    scale: useTransform(value, input, isMobile ? ['0.6', '0.6', '0.7', '0.7'] : ['0.895', '0.895', '1', '1']),
  };
  const frontLeft = {
    x: useTransform(value, input, ['0vw', '0vw', '-13.89vw', '-13.89vw']),
  };
  const frontLCloud2 = {
    x: frontLeft.x,
  };
  const frontRCloud = {
    x: useTransform(value, input, ['0vw', '0vw', '32.36vw', '32.36vw']),
  };
  return {
    clipPath,
    y,
    bigMonsterWrapper,
    rightSection,
    rMonster4,
    rCloud5,
    leftSection,
    lCloud2,
    boy,
    frontLeft,
    frontLCloud2,
    frontRCloud,
  };
}

export const stepCount = 3;

export const Cut3 = ({ scrollY, beginStep, step, isMobile }: SceneProps) => {
  const {
    clipPath,
    y,
    bigMonsterWrapper: bigMonsterWrapperStyle,
    rightSection: rightSectionStyle,
    rCloud5: rCloud5Style,
    leftSection: leftSectionStyle,
    lCloud2: lCloud2Style,
    boy: boyStyle,
    frontLeft: frontLeftStyle,
    frontLCloud2: frontLCloud2Style,
    frontRCloud: frontRCloudStyle,
  } = useParallax(scrollY, beginStep, stepCount + 1, isMobile);

  const [rMonster60Scope, rMonster60Animate] = useAnimate();
  const [rMonster70Scope, rMonster70Animate] = useAnimate();

  const handleMoveOnZoomIn = (i: number) => {
    switch (i) {
      case 0:
        rMonster60Animate(
          rMonster60Scope.current,
          {
            ...[{
              x: '7vw',
              y: '7vh',
            }, {
              x: '-7vw',
              y: '-7vh',
            }, {
              x: '-7vw',
              y: '7vh',
            }, {
              x: '7vw',
              y: '-7vh',
            }][Math.floor(Math.random() * 4)]
          },
          {
            type: 'tween',
            repeat: 0,
            duration: 1,
          },
        );
        break;
      case 1:
        rMonster70Animate(
          rMonster70Scope.current,
          {
            ...[{
              x: '7vw',
              y: '7vh',
            }, {
              x: '-7vw',
              y: '-7vh',
            }, {
              x: '-7vw',
              y: '7vh',
            }, {
              x: '7vw',
              y: '-7vh',
            }][Math.floor(Math.random() * 4)]
          },
          {
            type: 'tween',
            repeat: 0,
            duration: 1,
          },
        );
        break;
    }

  }

  const handleMoveOnZoomOut = (i: number) => {

    rMonster60Animate(
      rMonster60Scope.current,
      {
        x: '0vw',
        y: '0vh',
      },
      {
        type: 'tween',
        repeat: 0,
        duration: 1,
      },
    );
    rMonster70Animate(
      rMonster70Scope.current,
      {
        x: '0vw',
        y: '0vh',
      },
      {
        type: 'tween',
        repeat: 0,
        duration: 1,
      },
    );
  }
  return (
    <SharedS.Container style={{ y, clipPath }}>
      <SharedS.BgWrapper>
        <PreloadImage
          imageSrc={bg}
          alt=""
          style={{
            width: '100vw',
          }}
        />
        <S.BigMonsterWrapper style={bigMonsterWrapperStyle}>
          <PreloadImage
            imageSrc={bigMonster}
            alt=""
            style={{
              width: 'auto',
              height: '114vh',
            }}
          />
          <S.Eyes
            imageSrc={bigMonsterEyes}
            alt=""
            style={{
              width: 'auto',
              height: '114vh',
            }}
          />
          <S.Eyes
            imageSrc={bigMonsterMist}
            alt=""
            style={{
              width: 'auto',
              height: '114vh',
              filter: 'brightness(1.5)'
            }}
          />
        </S.BigMonsterWrapper>
        <S.RightSection style={rightSectionStyle}>
          <PreloadImage imageSrc={rCloud5} style={{
            y: '2vh',
            ...rCloud5Style,
            ...(isMobile && {
              width: '187vw',
              top: '-5vh'
            })
          }} />
          {!isMobile && <S.RMonster60 ref={rMonster60Scope} >
            <S.RMonster6 imageSrc={rMonster6} alt="" />
          </S.RMonster60>}
          {!isMobile && <S.RMonster70 ref={rMonster70Scope} >
            <S.RMonster7 imageSrc={rMonster7} alt="" />
          </S.RMonster70>}
        </S.RightSection>
        <S.LeftSection style={leftSectionStyle} >
          <PreloadImage imageSrc={lCloud2} alt="" style={lCloud2Style} />
        </S.LeftSection>
        <PreloadImage
          imageSrc={boy}
          alt=""
          align="bottom"
          style={{
            width: 'auto',
            height: '71.625vh',
            transformOrigin: 'left bottom',
            ...boyStyle,
          }}
        />
        {!isMobile && <S.FrontLeft style={frontLeftStyle} >
          <PreloadImage imageSrc={frontLCloud1} alt="" />
          <PreloadImage
            imageSrc={frontLCloud2}
            alt=""
            style={frontLCloud2Style}
          />
        </S.FrontLeft>}
        {!isMobile && <PreloadImage
          imageSrc={frontRCloud}
          alt=""
          align="bottom"
          justify="right"
          style={{
            width: '68.47vw',
            height: 'auto',
            zIndex: 1,
            ...frontRCloudStyle,
          }}
        />}
      </SharedS.BgWrapper>
      <S.ContentWrapper style={{
        opacity: 1,
        y: '10px'
      }}>
        <SharedS.Title src={isMobile ? titleMobile : title} className={step === 6 || step === 7 ? 'contentLoaded' : ''} />
        <SharedS.Content className={step === 6 || step === 7 ? 'contentLoaded' : ''}>
          Outmatched by overwhelming enemy forces, the Parades struggled against
          the ferocious beast onslaught. With the Glazed Organization faltering,
          they sought aid from celestial gods across the Four Divine Realms to
          bolster their fading power.
        </SharedS.Content>
      </S.ContentWrapper>
      {!isMobile && <S.MoveOn onMouseOver={() => { handleMoveOnZoomIn(0) }} onMouseLeave={() => { handleMoveOnZoomOut(1) }} style={{
        height: '30vh', top: 'calc(50% + 7vh)', width: '20vw', left: 'calc(100% - 24vw)',
      }} />}
      {!isMobile && <S.MoveOn onMouseOver={() => { handleMoveOnZoomIn(1) }} onMouseLeave={() => { handleMoveOnZoomOut(1) }} style={{
        height: '30vh', top: 'calc(50% + 17vh)', width: '20vw', left: 'calc(100% - 47vw)',
      }} />}
    </SharedS.Container>
  );
};

import { motion } from 'framer-motion';
import { media } from 'styled-bootstrap-grid';
import styled, { keyframes } from 'styled-components';

export const Container = styled(motion.div)`
  height: 120vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  // transform: translateZ(0);
  // &::after {
  //   background: rgba(0, 0, 0, 0.12);
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   bottom: 0;
  //   right: 0;
  //   z-index: 0;
  // }
`;

export const BgDownloadImage = styled(motion.img)`
  display: none;
`;

export const BgWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  pointer-events: none;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: block;
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

export const ContentWrapper = styled(motion.div)`
  font-family: 'Roboto Mono';
  color: #fff;
  position: absolute;
  top: 79vh;
  left: 50%;
  width: 90%;
  max-width: 600px;
  transform: translateX(-50%);
  text-align: center;
  z-index: 1;
`;

export const Title = styled(motion.img)`
  margin: 2vh auto;
  opacity: 0;
  width: 100%;
  max-width: 990px;
`;

export const Content = styled.p`
  font-size: 15px;
  line-height: 21px;
  opacity: 0;
  text-shadow: rgb(0, 0, 0, 0.5) 0.1em 0.1em 5px;
  margin: 0 auto;
  ${media.max.lg`
    width: 80%;
  `}
`;

export const breath = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
`;

export const Attack = styled(motion.div)<{ action: string }>`
  pointer-events: all;
  aspect-ratio: 1/1;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  line-height: 21px;
  color: #fff;
  font-family: 'Roboto Mono';
  z-index: 1;
  &:hover {
    cursor: pointer;
    &::before {
      animation: ${breath} 2s infinite;
      animation-timing-function: ease-in-out;
    }
  }

  &::before {
    position: absolute;
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    font-weight: bold;
    border: 2px dotted rgba(255, 255, 255, 0.7);
  }

  &::after {
    content: '${({ action }) => action}';
  }
`;

export const LinkButton = styled(motion.div)<{ action: string }>`
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-family: 'Roboto Mono';
  line-height: 21px;
  color: #fff;
  cursor: pointer;
  // background-color: rgba(255, 255, 255, 0.1);
`;

import { motion } from 'framer-motion';
import { media } from 'styled-bootstrap-grid';
import styled, { keyframes } from 'styled-components';

import { PreloadImage } from '../PreloadImage/PreloadImage';
import * as Shared from '../shared/styles';
import characterCenter from './images/characters-center.png';
import characterEffect from './images/characters-effect.png';
import charactersGhost from './images/characters-ghost.png';
import characterLeft from './images/characters-left.png';
import characterRight from './images/characters-right.png';

export const explosion = keyframes`
  0% {
    opacity: 0.33;
    transform: translateX(-50%) translateY(-50%) scale(0.33);
  }

  25% {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%) scale(1);
  }

  100% {
    opacity: 0;
    transform: translateX(-50%) translateY(-50%) scale(3.33);
  }
`;

const explosionDuration = 5;

export const Fragile = styled(PreloadImage)`
  width: 100vw;
  height: auto;
  transform-origin: center center;
  top: 50%;
  left: 50%;
  animation: ${explosion} ${explosionDuration}s linear infinite;

  & + & {
    animation-delay: ${explosionDuration / 2}s;
  }
`;

export const swing = keyframes`
  0% {
    transform: translateX(-5vw);
  }

  50% {
    transform: translateX(5vw);
  }

  100% {
    transform: translateX(-5vw);
  }
`;

export const swingDuration = 6;

export const StormWrapper = styled.div`
  animation: ${swing} ${swingDuration}s ease-in-out infinite;
`;

export const shake = keyframes`
  0% {
    transform: translateY(-2vh);
  }

  50% {
    transform: translateY(2vh);
  }

  100% {
    transform: translateY(-2vh);
  }
`;

export const StormInnerWrapper = styled.div`
  animation: ${shake} ${swingDuration * 1.5}s ease-in-out infinite;
`;

export const CharactersWrapper = styled(motion.div)`
  position: absolute;
  width: 204.125vh;
  height: 102.5vh;
  bottom: 0;
  left: calc(5vh + 50%);
`;

export const Character = styled(motion.div)`
  position: absolute;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  // pointer-events: auto;
  // cursor: pointer;
`;

export const CharacterCenter = styled(Character)`
  background-image: url(${characterCenter});
  top: calc(100% - 44vh);
  width: 30%;
  height: 32%;
  left: calc(50% - 30vh);
  background-size: contain;
  ${media.max.lg`
    top: calc(100% - 30vh);
    width: 75%;
    height: 20%;
    left: calc(50% - 17vh);
  `}
`;

export const CharacterLeft = styled(Character)`
  background-image: url(${characterLeft});
  top: calc(100% - 44vh);
  left: -3vw;
  width: 46%;
  height: 46%;
  background-size: contain;
  ${media.max.lg`
    width: 109%;
    top: calc(100% - 42vh);
    left: -40vw;
    height: 33%;
  `}
`;

export const CharacterRight = styled(Character)`
  background-image: url(${characterRight});
  top: calc(100% - 70vh);
  width: 46%;
  height: 64%;
  right: -13vw;
  background-size: contain;
  ${media.max.lg`
    width: 89%;
    top: calc(100% - 46vh);
    height: 43%;
    right: -40vw;
  `}
`;

export const CharacterEffect = styled(Character)`
  background-image: url(${characterEffect});
  top: calc(100% - 60vh);
  width: 47%;
  height: 55%;
  right: -4vw;
  background-size: contain;
  ${media.max.lg`
    width: 64%;
    top: calc(100% - 51vh);
    height: 42%;
    right: -29vw;
  `}
`;

export const CharacterGhost = styled(Character)`
  top: calc(50% - 30vh);
  left: -20vw;
  width: 50%;
  height: 50%;
  background-image: url(${charactersGhost});
  background-size: contain;
  ${media.max.lg`
    width: 106%;
    left: -57vw;
    height: 30%;
    top: calc(50% - 5vh);
  `}
`;

export const ContentWrapper = styled(Shared.ContentWrapper)`
  top: 45vh;
`;

export const MissionText = styled.span`
  font-size: 0.66em;
  text-transform: uppercase;
`;

export const MoveOn = styled.div`
  content: '';
  left: 0;
  top: calc(50% - 15vh);
  width: 15vw;
  height: 20vh;
  // background-color: rgba(255, 0, 0, .2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-family: 'Roboto Mono';
  line-height: 21px;
  color: #fff;
  cursor: pointer;
  z-index: 999 !important;
  position: absolute;
  cursor: pointer;
`
import { type MotionValue, useTransform } from 'framer-motion';

import { PreloadImage } from '../PreloadImage/PreloadImage';
import * as SharedS from '../shared/styles';
import { SceneProps } from '../shared/types';
import bg from './images/bg@0.5x.png';
import cloudFLeftBtm from './images/cloud-f-left-btm@0.5x.png';
import cloudFLeftTop from './images/cloud-f-left-top@0.5x.png';
import cloudFRight from './images/cloud-f-right@0.5x.png';
import cloudMLeftNew from './images/cloud-m-left-new@0.5x.png';
import cloudMRightBtmNew from './images/cloud-m-right-btm-new@0.5x.png';
import cloudMRightTop from './images/cloud-m-right-top@0.5x.png';
import dust from './images/dust@0.5x.png';
import dustFlip from './images/dust-flip@0.5x.png';
import falls from './images/falls@0.5x.png';
import feather1 from './images/feather-1@0.5x.png';
import feather2 from './images/feather-2@0.5x.png';
import feather3 from './images/feather-3@0.5x.png';
import title from './images/title.svg';
import * as S from './styles';

function useParallax(value: MotionValue<number>, begin: number, count: number, isMobile: boolean) {
  const input = Array.from({ length: count })
    .map((_, i) => i + begin)
    .map((i) => i * document.documentElement.clientHeight);
  const clipPath = useTransform(value, input, [
    'polygon(0% 0%, 0% 95%, 100% 100%, 100% 5%)',
    'polygon(0% 0%, 0% 95%, 100% 100%, 100% 8.3%)',
    'polygon(0% 0%, 0% 95%, 100% 100%, 100% 8.3%)',
    'polygon(0% 0%, 0% 91.7%, 100% 100%, 100% 8.3%)',
  ]);
  const y = useTransform(value, input, ['100vh', '-10vh', '-10vh', '-120vh']);
  const cloudMRightTop = {
    x: useTransform(value, input, isMobile ? ['12vw', '12vw', '55vw', '55vw'] : ['12.36vw', '12.36vw', '13.19vw', '13.19vw']),
    y: useTransform(value, input, isMobile ? ['-7vh', '-7vh', '-32vh', '-32vh'] : ['-7vh', '-7vh', '-9vh', '-9vh']),
  };
  const falls = {
    y: useTransform(value, input, ['-9vh', '-9vh', '-22.75vh', '-22.75vh']),
    scale: useTransform(value, input, ['1', '1', '1.154', '1.154']),
  };
  const cloudFLeftBtm = {
    x: useTransform(value, input, isMobile ? ['0vw', '0vw', '-32vw', '-32vw'] : ['0vw', '0vw', '-2vw', '-2vw']),
    y: useTransform(value, input, isMobile ? ['0vh', '0vh', '25vh', '25vh'] : ['0vh', '0vh', '12.5vh', '12.5vh']),
  };
  const feather1 = {
    x: useTransform(value, input, ['25.69vw', '25.69vw', '35.69vw', '35.69vw']),
    y: useTransform(value, input, [
      '16.625vh',
      '16.625vh',
      '18.625vh',
      '18.625vh',
    ]),
    rotate: useTransform(value, input, [
      '0deg',
      '0deg',
      '128.991deg',
      '128.991deg',
    ]),
    scale: useTransform(value, input, ['1', '1', '0.469', '0.469']),
  };
  const feather2 = {
    x: useTransform(value, input, ['22.5vw', '22.5vw', '26vw', '26vw']),
    y: useTransform(value, input, ['-13.5vh', '-13.5vh', '-32.5vh', '-32.5vh']),
    rotate: useTransform(value, input, [
      '-45deg',
      '-45deg',
      '79.653deg',
      '79.653deg',
    ]),
    scale: useTransform(value, input, ['1', '1', '0.652', '0.652']),
  };
  return {
    clipPath,
    y,
    cloudMRightTop,
    falls,
    cloudFLeftBtm,
    feather1,
    feather2,
  };
}

export const stepCount = 3;

export const Cut7 = ({
  scrollY,
  beginStep,
  isBoyFalling,
  isBoyFallen,
  onBoyClick,
  step,
  isMobile
}: SceneProps & {
  isBoyFalling: boolean;
  isBoyFallen: boolean;
  onBoyClick: () => void;
}) => {
  const {
    clipPath,
    y,
    cloudMRightTop: cloudMRightTopStyle,
    falls: fallsStyle,
    cloudFLeftBtm: cloudFLeftBtmStyle,
    feather1: feather1Style,
    feather2: feather2Style,
  } = useParallax(scrollY, beginStep, stepCount + 1, isMobile);

  return (
    <SharedS.Container style={{ y, clipPath }}>
      <SharedS.BgWrapper>
        <PreloadImage
          imageSrc={bg}
          style={{
            width: '100vw',
            transformOrigin: 'center top',
            ...(isMobile && {
              width: 'initial',
              height: '100vh',
              left: '-169vw',
              top: '10vh'
            }),
          }}
        />
        <PreloadImage
          shouldContain
          imageSrc={cloudMRightTop}
          justify="right"
          style={{
            width: 'auto',
            height: '57.875vh',
            transformOrigin: 'right top',
            ...cloudMRightTopStyle,
          }}
        />
        <S.Boy
          isFalling={isBoyFalling}
          isFallen={isBoyFallen}
          onClick={onBoyClick}
        />
        {!isMobile && <PreloadImage
          shouldContain
          imageSrc={falls}
          alt=""
          style={{
            width: 'auto',
            height: '147.5vh',
            transformOrigin: 'center top',
            left: '50%',
            x: '-50%',
            ...fallsStyle,
          }}
        />}
        <PreloadImage
          shouldContain
          imageSrc={cloudFLeftBtm}
          align="bottom"
          style={{
            width: 'auto',
            height: '53.5vh',
            transformOrigin: 'left bottom',
            ...cloudFLeftBtmStyle,
          }}
        />
        <S.Dust shouldContain imageSrc={dust} alt="" />
        <S.Dust shouldContain imageSrc={dustFlip} alt="" />
        <PreloadImage
          shouldContain
          imageSrc={feather1}
          alt=""
          style={{
            width: '10.48vw',
            height: 'auto',
            transformOrigin: '50% 50%',
            ...feather1Style,
          }}
        />
        <PreloadImage
          shouldContain
          imageSrc={feather2}
          alt=""
          align="bottom"
          style={{
            width: '18.4vw',
            height: 'auto',
            transformOrigin: '50% 50%',
            ...feather2Style,
          }}
        />
      </SharedS.BgWrapper>
      <SharedS.ContentWrapper>
        <SharedS.Title src={title} className={step === 14 || step === 15 ? 'contentLoaded' : ''} />
        <SharedS.Content className={step === 14 || step === 15 ? 'contentLoaded' : ''}>
          Amidst the devastation, a sole surviving god, Garuda, sacrificed his
          divinity, disrupting time to save the continent.
        </SharedS.Content>
      </SharedS.ContentWrapper>
    </SharedS.Container>
  );
};
